<template>
  <el-form class="content-wrap" ref="buyerInfo" :model="buyerInfo" label-width="120px" :rules="buyerInfoRules" @keyup.down.native="focusAround(true)" @keyup.up.native="focusAround(false)">
    <el-row>
      <el-form-item prop="buyerName" label="名 称:">
        <el-autocomplete id="buyerName" v-model="buyerInfo.buyerName" :fetch-suggestions="queryBuyerNameAsync" :trigger-on-focus="false" @select="handleSelect" placeholder="请输入购方名称/客户编号">
          <template slot-scope="{ item }">
            <div class="auto-drop">
              <div>
                <span>
                  <i v-if="item.isLocal" class="el-icon-suitcase local"></i>
                  <i v-else class="el-icon-cloudy"></i>
                  <span>{{ item.value }}</span>
                </span>
                <img v-if="item.star" src="@/assets/icon/star.png" />
              </div>
              <div>
                <span>{{ item.taxCode }}</span>
              </div>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item v-if="rules.indexOf('TITLE_TAX_NO') > 0" prop="buyerTaxNo" label="纳税人识别号:">
        <el-autocomplete v-model="buyerInfo.buyerTaxNo" :fetch-suggestions="queryBuyerTaxNoAsync" :trigger-on-focus="false" @select="handleSelect" placeholder="请输入购方税号">
          <template slot-scope="{ item }">
            <div class="auto-drop">
              <div>
                <span>
                  <i v-if="item.isLocal" class="el-icon-suitcase"></i>
                  <i v-else class="el-icon-cloudy"></i>
                  <span>{{ item.value }}</span>
                </span>
                <img v-if="item.star" src="@/assets/icon/star.png" />
              </div>
              <div>
                <span>{{ item.taxCode }}</span>
              </div>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item v-else prop="buyerTaxNo" label="纳税人识别号:">
        <el-input id="buyerTaxNo" class="pdt-input" v-model="buyerInfo.buyerTaxNo" placeholder="请输入纳税人识别码"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item prop="buyerAddressTel" label="地址、电话:">
        <el-input id="buyerAddressTel" class="pdt-input" :class="addressClass" v-model="buyerInfo.buyerAddressTel" placeholder="请输入纳税人地址、电话"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item prop="buyerBankAccount" label="开户行及账号:">
        <el-input id="buyerBankAccount" class="pdt-input" :class="bankClass" v-model="buyerInfo.buyerBankAccount" placeholder="请输入开户行及账号"></el-input>
      </el-form-item>
    </el-row>
  </el-form>
</template>

<script>
import { getBuyerNames, getBuyerTaxNo } from '@/service/invoice-open';
import { getTntConfig } from '@/service/system/config';

export default {
  name: 'InvoiceOpenUserInfo',
  props: ['buyerInfo', 'billingType', 'buyerType'],
  data() {
    return {
      buyerNames: [],
      addressClass: '',
      matchTaxCode: false,
      bankClass: '',
      buyerInfoRules: {},
      rules: [],
      focusList: ['buyerName', 'buyerTaxNo', 'buyerAddressTel', 'buyerBankAccount']
    };
  },
  created() {
    getTntConfig('MANUAL_SEARCH_BUYER').then(({ success, data }) => {
      if (success) this.rules = JSON.parse(data || '[]');
    });
  },
  watch: {
    buyerInfo: {
      handler() {
        this.bankClass = this.buyerInfo.buyerBankAccount && this.buyerInfo.buyerBankAccount.length > 24 ? 'long_class' : null;
        this.addressClass = this.buyerInfo.buyerAddressTel && this.buyerInfo.buyerAddressTel.length > 24 ? 'long_class' : null;
      },
      deep: true
    },
    billingType: {
      handler() {
        this.buyerInfoRulesChoose();
      }
    },
    buyerType: {
      handler() {
        this.buyerInfoRulesChoose();
      }
    }
  },
  methods: {
    buyerInfoRulesChoose() {
      console.log("---------------",this.buyerType);
      if(this.buyerType == true){
        this.buyerInfoRules = {
          buyerName: [{ required: true, message: '请输入购方姓名!', trigger: 'blur' }]
        };
        return;
      }
      if (this.billingType == 'Zzzp') {
        this.buyerInfoRules = {
          buyerName: [{ required: true, message: '请输入购方姓名!', trigger: 'blur' }],
          buyerTaxNo: [{ required: true, message: '请输入纳税人识别码!', trigger: 'blur' }],
          buyerAddressTel: [{ required: true, message: '请输入纳税人地址电话!', trigger: 'blur' }],
          buyerBankAccount: [{ required: true, message: '请输入开户行及账号!', trigger: 'blur' }]
        };
      }else if(this.billingType == 'QDZP') {
        this.buyerInfoRules = {
          buyerName: [{ required: true, message: '请输入购方姓名!', trigger: 'blur' }],
          buyerTaxNo: [{ required: true, message: '请输入纳税人识别码!', trigger: 'blur' }]
        };
      } else {
        this.buyerInfoRules = {
          buyerName: [{ required: true, message: '请输入购方姓名!', trigger: 'blur' }]
        };
      }
      this.$refs.buyerInfo.clearValidate();
    },
    // 远程搜索 （模糊搜索）
    queryBuyerNameAsync(value, fn) {
      getBuyerNames({ keyword: value }).then((res) => {
        if (res && res.success) {
          this.buyerNames = res.data.map((i) => (i.value = i.name));
        }
        fn(res.data);
      });
    },
    // 远程搜索 （模糊搜索）
    queryBuyerTaxNoAsync(value, fn) {
      getBuyerTaxNo({ keyword: value }).then((res) => {
        if (res && res.success) {
          this.buyerNames = res.data.map((i) => (i.value = i.name));
        }
        fn(res.data);
      });
    },
    // 键盘事件 上/下
    focusAround(type) {
      let domId = document.activeElement.id;
      let index = this.focusList.findIndex((i) => i === domId);
      if (type) {
        if (index < this.focusList.length - 1) {
          index++;
          domId = this.focusList[index];
        }
      } else {
        if (index > 0) {
          index--;
          domId = this.focusList[index];
        }
      }
      document.getElementById(domId).focus();
    },
    // 选中options
    handleSelect(item) {
      this.$emit('fitBuyerInfo', item);
      this.$refs.buyerInfo.validate();
    }
  }
};
</script>

<style scoped lang="scss">
.content-wrap {
  height: auto;
  padding-left: 20px;
  text-align: left;
}

::v-deep .el-row {
  margin: 16px 0;
}

::v-deep .el-form-item__label {
  color: #9d5224;
  text-align: left;
}

::v-deep .el-input__inner {
  width: 360px;
  padding: 0;
}

::v-deep .el-form-item__error {
  top: -12px;
  font-size: 12px;
}

.auto-drop {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  i {
    color: darkseagreen;
    margin-right: 8px;
  }

  .local {
    color: #3d94ff;
  }

  div:first-child {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #666666;

    img {
      width: 16px;
      height: 16px;
    }
  }

  div:last-child {
    margin-left: 22px;
    color: #999999;
  }
}

.tooltip-tax-no {
  padding-top: 6px;
  text-align: right;
}

.long_class {
  font-size: 12px;
}
</style>
