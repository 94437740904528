import { post, put } from "@/service/index";

// 拆分明细
export const splitInvoiceItem = (data) => {
  return post("/sal/invoice/item/split", data).then((res) => res);
};

// 合并明细
export const mergeInvoiceItem = (data) => {
  return post("/sal/invoice/item/merge", data).then((res) => res);
};

// 批量修改
export const batchUpdateItem = (data) => {
  return put("/sal/invoice/item/batch", data).then((res) => res);
};
