import { post, put, del ,get } from './index';
// 新增开票税率
export const addSalTaxRate = (data) => {
  return post('/sal/tax/rate', data).then((res) => res);
};
// 修改开票税率
export const revampTaxRateList = (data) => {
  return put('/sal/tax/rate', data).then((res) => res);
};
// 批量删除开票税率
export const taxRateBatchDelete = (ids) => {
  return del(`/sal/tax/rate/batchDelete/${ids}`).then((res) => res);
};
// 分页获取开票税率列表
export const getTaxRateList = (data) => {
  return get('/sal/tax/rate/list', data).then((res) => res);
};
// 获取开票税率
export const salTaxRate = (id) => {
  return get(`/sal/tax/rate/${id}`).then((res) => res);
};
// 删除开票税率
export const taxRateList = (id) => {
  return del(`/sal/tax/rate/${id}`).then((res) => res);
};

// 新增当前组织的可开发票类型;
export const billtypeaddAsync = (data) => {
  return post('/sal/bill/type/add', data).then(res => res);
}
// 批量删除当前组织的可开发票类型;
export const billtypeBatchDelete = (ids ) => {
  return del(`/sal/bill/type/batchDelete/${ids}`).then((res) => res);
};
// 查询当前组织下可开发票类型关系;
export const billtypeGetSalBillingTypeList = (data) => {
  return get('/sal/bill/type/getSalBillingTypeList', data).then((res) => res);
};
// 修改当前组织的可开发票类型;
export const salBillTypeUpdate = (data) => {
  return post('/sal/bill/type/update', data).then((res) => res);
};
// 获取当前组织的发票类型详情;
export const getSalBillType = (id) => {
  return get(`/sal/bill/type/${id}`).then((res) => res);
};



// 新增开票限额
export const addSalLimit = (data) => {
  return post('/sal/limit', data).then(res => res);
};
// 修改开票限额
export const alterSalLimit = (data) => {
  return put('/sal/limit', data).then((res) => res);
};
// 批量删除开票限额
export const salLimitDelALL = (ids) => {
  return del(`/sal/limit/batchDelete/${ids}`).then((res) => res);
};
// 分页获取开票限额列表
export const salLimitList = (data) => {
  return get('/sal/limit/list', data).then((res) => res);
};
// 获取开票限额
export const getSalLimit = (id) => {
  return get(`/sal/limit/${id}`).then((res) => res);
};
// 删除开票限额
export const salLimitDel = (id) => {
  return del(`/sal/limit/${id}`).then((res) => res);
};

// /sal/bill/type/list
export const salBillTypeListGet = (data) => {
  return get('/sal/config/invoice/bill', data).then(res => res)
}
