<template>
  <div class="content-wrap">
    <div class="row-first">
      <label>当前组织：</label>
      <span>{{ this.orgName }}</span>
    </div>
    <div class="row-two">
      <div class="allowance">
        <label>分机编号：</label>
        <span>{{ extensionNo }}</span>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <el-table :data="records"
                  border
                  show-summary
                  :summary-method="getSummaries"
                  v-loading="tableLoading"
                  :header-cell-style="handleHeaderCellStyle"
                  style="width: 100%">
          <el-table-column prop="billingType" label="发票种类" width="140" :formatter="fmtBillingType"></el-table-column>
          <el-table-column prop="invoiceCode" label="发票代码" width="120"></el-table-column>
          <el-table-column prop="invoiceNoStart" label="发票号码起" width="120"></el-table-column>
          <el-table-column prop="invoiceNoEnd" label="发票号码止" width="120"></el-table-column>
          <el-table-column prop="residueQuantity" label="发票余量" width="100"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="allowance-but">
      <el-button @click="handleClose" class="allowance-button" type="primary">关闭</el-button>
    </div>
  </div>
</template>

<script>
import { tableStyle } from '@/util/mixins'

import {
  getInventory
} from '@/service/sal/invoice'

export default {
  name: 'InvoiceAllowanceDialog',
  mixins: [tableStyle],
  props: ['orgId', 'billingType', 'orgName'],
  data () {
    return {
      tableLoading: false,
      records: [],
      extensionNo: '',    //分机号
      identifier: '',      //终端号
      billingChannel: ''      //开票方式
    }
  },
  created () {
    this.handleGetInventory()
  },
  methods: {
    // 获取库存信息
    async handleGetInventory () {
      this.tableLoading = true
      const { success, data } = await getInventory(
        {
          'orgId': this.orgId,
          'equipmentId': this.equipmentId,
          'billingType': this.billingType
        }
      )
      if (success) {
        this.extensionNo = data.machineNo //分机号
        this.identifier = data.identifier //开票终端标识或终端号
        this.billingChannel = data.billingChannel //开票方式
        this.records = data.inventoryItemVO //库存明细
      }
      this.tableLoading = false
    },
    handleClose () {
      this.$emit('handleCloseAllowance')
    },
    //格式化发票类型
    fmtBillingType (row) {
      let value = row.billingType
      return this.handleValueToLabel('BillingType', value)
    },
    //合计行
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index === 1) {
          sums[index] = ''
          return
        }
        if (index === 2) {
          sums[index] = ''
          return
        }
        if (index === 3) {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    //补齐位数
    formatZero (num, len) {
      if (String(num).length > len) return num
      return (Array(len).join(0) + num).slice(-len)
    }
  }
}
</script>

<style scoped lang="scss">
  .content-wrap {
    padding: 0 24px;
    width: auto;
  }

  .content-select {
    display: flex;
    flex-direction: row;
    box-shadow: none;
    justify-content: space-between;

    label {
      width: 80px;
      margin-right: 16px;
    }
  }

  .content-main {
    padding: 24px 0;
    width: 100%;
    margin-bottom: 0;
  }

  .content-table {
    width: 100%
  }

  .row-first {
    display: flex;
  }

  .row-two {
    display: flex;
  }

  .allowance {
    margin-right: 100px;
  }

  .allowance-but {
    padding-bottom: 20px;
  }

  .allowance-button {
    display: flex;
    margin: 0 auto;
  }
</style>
