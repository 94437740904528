<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm">
          <el-row class="row-first">
            <el-col :span="7">
              <el-form-item label="生成时间" prop="createdRange">
                <el-date-picker
                  v-model="createdRange"
                  type="daterange"
                  range-separator=""
                  clearable
                  size="mini"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="客户名称" prop="buyerName">
                <el-input
                  v-model.trim="queryForm.buyerName"
                  placeholder="请输入客户名称"
                  maxlength="64"
                  clearable
                  @keyup.enter.native="handleGetBillingTradeList()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="订单编号" prop="dataId">
                <el-input
                  v-model.trim="queryForm.dataId"
                  placeholder="请输入订单编号"
                  maxlength="32"
                  clearable
                  @keyup.enter.native="handleGetBillingTradeList()"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="发票类型" prop="billingType">
                <el-select
                  v-model="queryForm.billingType"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in invoiceType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="select-button">
          <select-button
            :show-status="false"
            @list-close="handleListClose"
            @list-show="handleListShow"
            @select="handleQuery"
            @reset="handleReset"
          ></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <span>票据列表</span>
          <div>
            <el-button type="default" @click="handleTemplate"
              >下载模板</el-button
            >
            <el-button type="primary" @click="handleMergeInvoice"
              >合并发票</el-button
            >
            <el-button type="info" @click="handleImport">导入开票</el-button>
          </div>
        </div>
        <div class="content-table">
          <el-table
            border
            stripe
            :data="tableData"
            ref="multipleTable"
            v-loading="recordsLoading"
            :row-key="handleGetRowKey"
            @row-click="handleOpenDetails"
            :header-cell-style="handleHeaderCellStyle"
            @selection-change="handleSelectionChange"
            style="width: 100%"
          >
            <el-table-column
              type="selection"
              width="50"
              :reserve-selection="true"
              fixed="left"
            />
            <el-table-column
              type="index"
              label="序号"
              width="60"
              fixed="left"
              class-name="dl-pointer"
            />
            <el-table-column prop="created" label="订单提交时间" width="180" />
            <el-table-column prop="extendBizNo" label="PMS订单号" width="250" />
            <el-table-column prop="roomId" label="房间号" width="100" />
            <el-table-column
              prop="buyerName"
              label="客户名称"
              min-width="220"
              show-overflow-tooltip
            />
            <el-table-column prop="buyerTaxNo" label="客户税号" width="170" />
            <el-table-column
              prop="sumAmount"
              label="价税合计"
              width="100"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              prop="billingFrom"
              label="业务类型"
              width="110"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              prop="billingType"
              label="发票类型"
              width="110"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              prop="source"
              label="数据来源"
              width="110"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              prop="sendMode"
              label="取票方式"
              width="80"
              :formatter="handleTableValueFormat"
            />
            <el-table-column prop="dataId" label="订单编号" width="250" />
            <el-table-column
              prop="operate"
              label="操作"
              fixed="right"
              width="135"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleInvoiceIssue(scope.row)"
                  >开具</el-button
                >
                <el-button type="text" @click="handleInvoiceTitle(scope.row)"
                  >修改</el-button
                >
                <el-button type="text" @click="handleInvoiceDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页组件 -->
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.page"
          :page-sizes="pageSizes"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 发票拆分 -->
    <invoice-split
      ref="invoiceSplit"
      :invoiceId="invoiceId"
      @success="handleInvoiceSplitSuccess"
    />
    <invoice-merge
      ref="invoiceMerge"
      :invoices="mergeBillData"
      @success="handleInvoiceSplitSuccess"
    />
    <invoice-bill
      v-if="issueVisible"
      :show.sync="issueVisible"
      :invoice="issueData"
      @invoice-close="handleQuery"
      @billing-invoice="handleBillingInvoice"
    >
    </invoice-bill>
    <!-- 提示弹窗 -->
    <el-dialog
      append-to-body
      title="提示"
      :visible.sync="titleVisible"
      width="30%"
    >
      <div class="dialog-content">{{ titleContent }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="titleVisible = false">确定</el-button>
      </div>
    </el-dialog>
    <!-- 删除弹窗 -->
    <invoice-delete
      ref="invoiceDelete"
      :invoices="deleteInvoices"
      @success="handleQuery"
    />
    <update-buyer
      :show.sync="invoiceTitleVisible"
      :invoice-title="invoiceTitle"
      @success="handleQuery"
    />
    <el-dialog
      append-to-body
      :title="billingReturnInfo"
      :visible.sync="billingReturnInfoVisible"
      @close="closeBillingReturnInfo"
      width="410px"
    >
      <invoice-billing-return-info
        :printInfo="printInfo"
        :billingInfo="billingInfo"
        :buttonShow="buttonShow"
        @closeBillingReturnInfo="closeBillingReturnInfo"
        @navigateToAlready="navigateToAlready"
        :show.sync="issueVisible"
      >
      </invoice-billing-return-info>
    </el-dialog>
    <!-- 模版下载 -->
    <invoice-tmpl ref="invoiceTmpl" />
  </div>
</template>

<script>
import {
  billInvoice,
  downloadTemplate,
  getInventory,
  mergeInvoice,
  waitList,
} from "@/service/sal/invoice";

import { OPEN_INVOICE_TYPES } from "@/content/invoice.js";
import {
  changeMethods,
  commonsMethods,
  dateOption,
  tableStyle,
} from "@/util/mixins";
import InvoiceTmpl from "@/views/invoice-open/components/invoice-tmpl";
import InvoiceBill from "@/views/invoice-open/components/invoice-bill";
import UpdateBuyer from "@/views/invoice-open/components/update-buyer";
import InvoiceSplit from "@/views/invoice-open/components/invoice-split";
import InvoiceMerge from "@/views/invoice-open/components/invoice-merge";
import InvoiceDelete from "@/views/invoice-open/components/invoice-delete";
import { downloadStaticFile } from "@/service/common";
import InvoiceBillingReturnInfo from "@/views/invoice-open/invoice-issue/components/InvoiceBillingReturnInfo";

export default {
  name: "HotelWaitInvoice",
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  components: {
    InvoiceTmpl,
    InvoiceBill,
    UpdateBuyer,
    InvoiceSplit,
    InvoiceMerge,
    InvoiceDelete,
    InvoiceBillingReturnInfo,
  },
  props: ["ids"],
  data() {
    return {
      queryForm: {
        buyerName: undefined,
        dataId: undefined,
        billingType: "",
        page: 1,
        size: 15,
      },
      createdRange: [],
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100],
      invoiceType: OPEN_INVOICE_TYPES,
      isSplit: true,
      tableData: [],
      invoiceId: 0,
      /* 合并单据数据区 */
      mergeBillData: [],
      /*开具数据区*/
      issueVisible: false,
      issueData: {},
      issueDetailVisible: false,
      titleVisible: false,
      titleContent: "",
      forceUnlock: false,
      forceUnlockData: {},
      recordsLoading: false,
      invoiceTitleVisible: false,
      invoiceTitle: {},
      /* 发票删除数据区 */
      deleteVisible: false,
      deleteTitle: "是否删除当前信息",
      deleteInvoices: [],
      selectExpended: false,
      billingReturnInfo: "",
      billingReturnInfoVisible: false,
      printInfo: "",
      billingInfo: "",
      buttonShow: false,
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    handleTemplate() {
      this.$refs.invoiceTmpl.open();
    },
    handleImport() {
      this.$message.warning("功能即将上线！");
    },
    /* 表格值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case "价税合计":
          return "￥" + value;
        case "发票类型":
          return this.handleValueToLabel("BillingType", value);
        case "数据来源":
          return this.handleValueToLabel("SystemSource", value);
        case "业务类型":
          return this.handleValueToLabel("BillingFrom", value);
        case "取票方式":
          return this.handleValueToLabel("BillingSendMode", value);
        case "打印状态":
          return this.handleValueToLabel("PrintingStatus", value);
        default:
          return value;
      }
    },
    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    // 重置筛选列表
    handleReset() {
      this.resetForm("queryForm");
    },
    /* 条件查询 */
    handleQuery() {
      this.handleGetBillingTradeList();
    },
    /* 待开发票列表获取 */
    async handleGetBillingTradeList() {
      this.recordsLoading = true;
      if (this.ids) {
        this.queryForm.ids = JSON.parse(this.ids);
      }
      const { success, data } = await waitList(
        this.addDateRange(this.queryForm, this.createdRange, "Created")
      );
      this.recordsLoading = false;
      if (success) {
        this.tableData = data.records;
        this.dataTotal = data.total;
      }
    },
    /* 选中数据 */
    handleSelectionChange(invoice) {
      this.mergeBillData = invoice;
    },
    /* 合并发票 */
    handleMergeInvoice() {
      setTimeout(() => {
        this.$refs.invoiceMerge.open();
      }, 0);
    },
    /* 发票明细 */
    handleOpenDetails(row, column) {
      if (["序号"].indexOf(column.label) == -1) {
        if ([undefined, "操作"].indexOf(column.label) == -1) {
          this.toast("点击“序号”列表区域，查看该票明细信息！", "info");
        }
        return;
      }

      this.invoiceId = row.id;
      setTimeout(() => {
        this.$refs.invoiceSplit.open();
      }, 0);
    },
    /* 明细弹窗关闭 */
    handleInvoiceSplitSuccess() {
      this.$refs.multipleTable.clearSelection();
      this.handleQuery();
    },
    /* 切换分页条数 */
    handleSizeChange(value) {
      this.queryForm.size = value;
      this.handleQuery();
    },
    /* 翻页 */
    handleCurrentChange(value) {
      this.queryForm.page = value;
      this.handleQuery();
    },
    /* 记住选中 */
    handleGetRowKey(row) {
      return row.id;
    },
    /* 发票开具 */
    handleInvoiceIssue(invoice) {
      if (invoice.sumAmount == 0) {
        this.toast("当前价税合计为0，无法开具！", "warning");
        return;
      }
      this.issueVisible = true;
      this.issueData = invoice;
    },
    /* 发票开具 */
    async handleBillingInvoice(printable, back) {
      //判断连开带打的标识
      if (printable == "Y") {
        this.issueData.printable = true;
      } else {
        this.issueData.printable = false;
      }
      const { success, data } = await billInvoice({
        id: this.issueData.id,
        printable: this.issueData.printable,
      });
      if (success) {
        if (data.isSync) {
          this.billingInvoiceId = data.billingInvoiceId;

          if (data.isPrint) {
            if (data.billingState) {
              this.billingReturnInfo = "发票开具成功！";
              this.billingReturnInfoVisible = true;
              if (data.printState) {
                this.printInfo = "正在驱动打印机执行打印操作，请稍后......";
              } else {
                this.printInfo = "驱动打印机失败：根据接口返回原因进行展示";
              }
            } else {
              this.printInfo = "错误原因：根据接口返回原因进行展示！";
              this.billingInfo = '你可以在"开票记录"中重试。';
              this.billingReturnInfo = "发票开具失败！";
              this.buttonShow = true;
              this.billingReturnInfoVisible = true;
            }
          }
        }
        if (back) back(success);
      }
      await this.handleGetBillingTradeList();
    },
    /* 发票抬头修改 */
    handleInvoiceTitle(invoice) {
      this.invoiceTitle = JSON.parse(JSON.stringify(invoice));
      this.invoiceTitleVisible = true;
    },
    /* 发票删除 */
    handleInvoiceDelete(invoice) {
      this.deleteInvoices = [invoice];
      setTimeout(() => {
        this.$refs.invoiceDelete.open();
      }, 0);
    },
    /* 删除确认 */
    handleInvoiceDeleteConfirm(invoiceId) {
      deleteInvoice(invoiceId).then((res) => {
        if (res.success) {
          this.deleteVisible = false;
          this.toast("删除发票成功", "success");
          this.handleQuery();
        }
      });
    },
    closeBillingReturnInfo() {
      this.issueVisible = false;
      this.billingReturnInfoVisible = false;
    },
    navigateToAlready() {
      this.$emit("navigateToAlready");
    },
  },
};
</script>

<style lang="scss" scoped>
.content-select {
  .select-body {
    padding: 24px 0 24px 24px;
  }
}

.content-main {
  min-height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    span {
      font-size: 16px;
      color: #666666;
    }

    .el-button {
      width: 80px;
      padding: 0;
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }
  }
}

.content-main {
  .invoice-wait-table ::v-deep .DisableSelection > .cell {
    display: none !important;
  }
}

.dialog-content {
  text-align: left;
  margin-top: -6px;
  margin-left: 24px;
}

.dialog-body {
  margin: -16px 24px 0 24px;

  .body-top {
    .top-first-row {
      display: flex;
      margin-bottom: 12px;
    }

    .top-second-row {
      display: flex;
      text-align: left;
      margin-bottom: 24px;

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 20em;
      }
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .body-bottom {
    text-align: left;
    border-top: 1px solid #e9e9e9;
    margin: 0 -24px;
    padding: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}

.el-dialog__footer {
  padding: 10px 24px 24px;
  text-align: right;
  box-sizing: border-box;
}
</style>

