<template>
  <div class="billingReturnInfo-wrap">
    <div>
      <span>{{ printInfo }}</span>
      <span>{{ billingInfo }}</span>
    </div>
    <div v-if="buttonShow" class="footer">
      <el-button type="default" @click="closeBillingReturn">关闭</el-button>
      <el-button type="primary" @click="openAlreadyInvoice">开具记录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceBillingReturnInfo',
  props: ['printInfo', 'billingInfo', 'buttonShow'],

  data () {
    return {}
  },
  methods: {
    closeBillingReturn () {
      this.$emit('closeBillingReturnInfo')
      this.$emit('update:show', false)
    },
    openAlreadyInvoice () {
      this.$emit('update:show', false)
      this.$emit('navigateToAlready')
    }
  }
}
</script>

<style scoped lang="scss">
.billingReturnInfo-wrap {
  margin-top: -30px;
  padding: 24px 24px;
  font-size: 14px;

  .footer {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>