import {download, post} from '../index';

export const markItemSheetStatus = (data) => {
    return post('/sal/ori/trade/item/markItemSheetStatus', data).then((res) => res);
};

export const itemExport = (data) => {
    return download('/sal/ori/trade/item/itemExport', data).then((res) => {
        let blob = new Blob([res.data], {type: 'application/xlsx'});
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement('a'); // 创建a标签
        link.href = url;
        link.download = '原始订单数据文件.xlsx'; // 重命名文件
        link.click();
        URL.revokeObjectURL(url);
    });
};