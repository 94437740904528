<template>
  <el-dialog title="修改发票状态" width="700px" append-to-body :visible.sync="invoiceStatusVisible">
    <div class="form-body" style="display:flex;justify-content:center;">
      <el-form ref="recordForm" :model="detailModel" :rules="ruless" label-width="120px">
        <el-row style="margin-bottom: 30px">
          <el-col :span="8">
            <el-form-item label="发票状态:" prop="invoiceStatus">
              <el-select v-model="detailModel.invoiceStatus" @change="handleSelectAction" filterable collapse-tags placeholder="请选择">
                <el-option v-for="item in editInvoiceStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 30px">
          <el-col :span="8">
            <el-form-item label="失败原因:" prop="failedMessage">
              <el-input v-model="detailModel.failedMessage" :disabled="failedMessageDisable" maxlength="128"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 30px">
          <el-col :span="8">
            <el-form-item label="发票代码:" prop="invoiceCode">
              <el-input v-model="detailModel.invoiceCode" :disabled="invoiceDisable" maxlength="25"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 30px">
          <el-col :span="8">
            <el-form-item label="发票号码:" prop="invoiceNo">
              <el-input v-model="detailModel.invoiceNo" :disabled="invoiceDisable" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 30px">
          <el-col :span="8">
            <el-form-item label="开票日期:" prop="invoiceTime">
              <el-date-picker v-model="detailModel.invoiceTime"
                              :disabled="invoiceDisable"
                              type="datetime"
                              width="450"
                              popper-class="kaiPiao"
                              placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="dialog-footer" slot="footer" style="margin-right: 5px">
      <el-button @click="invoiceStatusVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitInvoiceStatusBtn">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { submitInvoiceStatus } from '@/service/invoice-update-status'

export default {
  name: 'invoiceUpdateStatus',
  props: {
    updateInvoice: {
      type: Object,
      default: () => {
      }
    },
  },
  data () {
    return {
      invoiceStatusVisible: false,
      editInvoiceStatus: [
        {
          value: 'YKJ',
          label: '已开具'
        },
        {
          value: 'YCH',
          label: '已冲红'
        },
        {
          value: 'YZF',
          label: '已作废'
        },
        {
          value: 'KJSB',
          label: '开具失败'
        },
        {
          value: 'ZFSB',
          label: '作废失败'
        },
        {
          value: 'CHSB',
          label: '冲红失败'
        }
      ],
      recordsLoading: false,
      detailModel: {},
      failedMessageDisable: false,
      invoiceDisable: false,
      rules1: {
        invoiceStatus: [
          {required: true}
        ],
        invoiceCode: [
          {required: true, message: '请输入发票代码!', trigger: 'blur'}
        ],
        invoiceNo: [
          {required: true, message: '请输入发票号码!', trigger: 'blur'}
        ],
        invoiceTime: [
          {required: true, message: '请选择开票日期!', trigger: 'blur'}
        ]
      },
      rules2: {
        invoiceStatus: [
          {required: true}
        ],
        failedMessage: [
          {required: true, message: '请输入失败原因!', trigger: 'blur'}
        ]
      },
      rules3: {
        invoiceStatus: [
          {required: true}
        ],
        failedMessage: [
          {required: true, message: '请输入失败原因!', trigger: 'blur'}
        ],
        invoiceCode: [
          {required: true, message: '请输入发票代码!', trigger: 'blur'}
        ],
        invoiceNo: [
          {required: true, message: '请输入发票号码!', trigger: 'blur'}
        ],
        invoiceTime: [
          {required: true, message: '请选择开票日期!', trigger: 'blur'}
        ]
      },
      rules4: {
        invoiceStatus: [
          {required: true}
        ],
        invoiceNo: [
          {required: true, message: '请输入发票号码!', trigger: 'blur'}
        ],
        invoiceTime: [
          {required: true, message: '请选择开票日期!', trigger: 'blur'}
        ]
      },
    }
  },
  created() {

  },
  computed: {
    ruless: function () {
      if (this.detailModel.invoiceStatus === 'YKJ' || this.detailModel.invoiceStatus === 'YCH' || this.detailModel.invoiceStatus === 'YZF') {
        this.failedMessageDisable = true
        this.invoiceDisable = false
        this.detailModel.failedMessage = ''
        if(this.detailModel.billingType == 'QDPP' || this.detailModel.billingType == 'QDZP'){
          return this.rules4
        }
        return this.rules1
      } else if (this.detailModel.invoiceStatus === 'KJSB') {
        this.failedMessageDisable = false
        this.invoiceDisable = true
        this.detailModel.invoiceNo = ''
        this.detailModel.invoiceCode = ''
        this.detailModel.invoiceTime = ''
        return this.rules2
      } else {
        this.failedMessageDisable = false
        this.invoiceDisable = false
        return this.rules3
      }
    }
  },
  methods: {
    open () {
      this.detailModel = this.updateInvoice
      this.invoiceStatusVisible = true
      this.handleSelectAction()
    },
    handleCancel () {
      this.invoiceStatusVisible = false
    },
    async submitInvoiceStatusBtn () {
      if(this.detailModel.billingFlag == 'Hp' && (this.detailModel.invoiceStatus == 'CHSB' || this.detailModel.invoiceStatus == 'YCH')){
        this.toast('红票不能进行冲红操作', 'error');
        return;
      }

      this.$refs.recordForm.validate(async (valid) => {
        if (!valid) return

        this.recordsLoading = true
        const { success } = await submitInvoiceStatus(this.detailModel)
        this.recordsLoading = false
        if (success) {
          this.$emit("success")
        }
        this.invoiceStatusVisible = false
        this.resetInvoiceStatus()
      })

    },
    resetInvoiceStatus () {
      this.$emit("resetBtn")
    },
    handleSelectAction (value) {
      console.log(value)

      //this.$refs.recordForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
  .tmpl-cont {
    padding: 0px 20px 20px 20px;

    a, a:hover {
      color: #3D94FF;
      font-size: 16px;
      font-weight: 400;
      line-height: 2em;
      text-decoration: underline;
    }
  }
  .form-body {
    margin-bottom: 24px;

    .el-row {
      margin: 24px 0px;

      &:last-child {
        padding-bottom: 20px;
      }
      //.el-form-item__error {
      //  width: 130px;
      //}
    }

    label {
      width: 70px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 20px;
    }

    span {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 20px;
    }

  }

</style>
<style lang="scss">
.form-body {
  .el-form-item__error {
    width: 130px;
  }
}
</style>
