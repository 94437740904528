<template>
  <div class="dialog-wrap">
    <div class="title-button">
      <span @click="handleAddPerson">新增</span>
    </div>
    <div class="dialog-content">
      <el-table :data="tableData" stripe border
                v-loading="tableLoading"
                :header-cell-style="handleHeaderCellStyle"
                style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="auditor" label="复核人" min-width="120" align="left">
          <template slot-scope="scope">
            <el-input v-model="scope.row.auditor" :disabled="!scope.row.editing"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="payee" label="收款人" min-width="120">
          <template slot-scope="scope">
            <el-input v-model="scope.row.payee" :disabled="!scope.row.editing"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="defaulted" label="默认" min-width="80">
          <template slot-scope="scope">
            <el-switch :disabled="editIndex!=undefined"
                       v-model="scope.row.defaulted"
                       @change="handleDefaultChange(scope.row)"
                       active-color="#1890FF"
                       inactive-color="#F5222D">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="operate" label="操作" min-width="100">
          <template slot-scope="scope">
            <div>
              <el-button style="padding: 0px 3px;" type="text"
                         @click="handleSubmit(scope.row,scope.$index)"
                         v-if="scope.row.editing">保存
              </el-button>
              <el-button style="padding: 0px 3px;" type="text"
                         @click="handleUpdate(scope.row,scope.$index)"
                         v-else>编辑
              </el-button>
              <el-button style="padding: 0px 3px;"
                         v-show="editIndex==scope.$index" type="text"
                         @click="handleCancel(scope.row)">取消
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page.sync="selectParam.page"
                     :page-sizes="pageSizes"
                     :page-size="selectParam.size"
                     layout="total, sizes, prev, pager, next"
                     :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getBillingOpenPersonSetting,
  saveOrUpdateBillingOpenPersonMsg
} from '@/service/invoice-open'
import { setOwnConfig, delOwnConfig } from '@/service/system/config'

export default {
  name: 'BillingOpenPersonSetting',
  data () {
    return {
      selectParam: {
        page: 1,
        size: 10
      },
      saveForm: {
        'auditor': undefined,
        'payee': undefined,
        'payer': undefined,
        'noEdit': false
      },
      tableData: [],
      tableLoading: false,
      page: { total: 0 },
      pageSizes: [10, 20, 30, 50],
      defaultBillingConfig: {},
      bakRow: undefined,
      editIndex: undefined
    }
  },
  created () {
    this.selectPage()
  },
  methods: {
    selectPage () {
      this.tableLoading = true
      getBillingOpenPersonSetting(this.selectParam).then(res => {
        this.tableLoading = false
        if (res.success) {
          let records = res.data.records
          records.forEach(item => {
            item.editing = false
          })
          this.tableData = records
          this.page.total = res.data.total
        }
      })
    },
    //切换分页条数
    handleSizeChange (value) {
      this.selectParam.size = value
      this.selectPage()
    },
    //翻页
    handleCurrentChange (value) {
      this.selectParam.page = value
      this.selectPage()
    },
    //新增
    handleAddPerson () {
      if (this.editIndex != undefined) {
        this.toast('请将正在编辑的信息保存后再添加~', 'warning')
        return
      }
      this.tableData.forEach(i => i.editing = false)
      this.saveForm = {
        'auditor': undefined,
        'payee': undefined,
        'payer': undefined,
        'defaulted': true,
        'editing': true
      }
      this.tableData.push(this.saveForm)
      this.editIndex = this.tableData.length - 1
    },
    handleCancel (row) {
      if (row.id == undefined) {
        this.tableData.splice(this.editIndex, 1)
      } else {
        this.$set(this.tableData, this.editIndex, JSON.parse(this.bakRow))
      }
      this.editIndex = undefined
    },
    handleUpdate (row, index) {
      this.bakRow = JSON.stringify(row)
      this.tableData.forEach(i => i.editing = false)
      row.editing = true
      this.editIndex = index
      this.$set(this.tableData, this.editIndex, row)
      this.saveForm = row
    },
    //操作 编辑或者保存
    handleSubmit (row) {
      if (!this.saveForm.auditor
        || !this.saveForm.payee) {
        this.toast('请将信息补充完整再保存~', 'warning')
        return
      }
      this.tableLoading = true
      saveOrUpdateBillingOpenPersonMsg(this.saveForm).then(res => {
        this.editIndex = undefined
        this.tableLoading = false
        if (res.success) {
          if (this.saveForm.id == null) {
            this.toast('新增开票人信息成功', 'success')
          } else {
            this.toast('修改开票人信息成功', 'success')
          }
          this.selectPage()
          if (row.defaulted) {
            this.$emit('persons-change')
          }
        }
      })
    },
    //设置默认设备
    handleDefaultChange (row) {
      if (row.id == null) {
        return
      }
      this.tableLoading = true
      setOwnConfig({ 'configs': { 'BILL_DRAWER_CFG_ID': row.defaulted ? row.id : 0 } }).then(res => {
        if (res.success) {
          this.toast('修改默认开票人信息成功', 'success')
          this.$emit('persons-change')
          this.selectPage()
        }
        this.tableLoading = false
      })
    },
    // 表头样式
    handleHeaderCellStyle: function () {
      return 'background-color:#EBF4FF;color:#333333;font-wight:400;'
    }
  }
}
</script>

<style lang="scss" scoped>

  ::v-deep .el-table .cell {
    height: initial !important;
    line-height: initial !important;
    overflow: initial !important;
  }

  ::v-deep .el-input {
    height: initial !important;
    line-height: initial !important;
    width: initial !important;
  }

  .dialog-wrap {
    margin: 0px 30px 0px 30px;

    .el-input__inner, .el-input {
      width: 100%;
    }

    .dialog-content {
      padding: 0px 0px 30px 0px;
    }

    .title-button {
      text-align: right;
      padding: 10px;

      span {
        color: #1890ff;
        font-size: 18px;
        font-weight: 500;
      }

      span:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }

    ::v-deep .el-input__inner {
      text-align: center;
      background-color: white;
      border: none;

    }
  }
</style>
