<template>
  <el-dialog
    append-to-body
    title="修改税率说明"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
  >
    <div class="cont">
      <el-form label-width="100px" v-model="dataForm">
        <el-form-item label="发票条数：">
          <span>{{ invoices.length }}&nbsp;条</span>
        </el-form-item>
        <el-form-item label="税率说明：">
          <el-select
            v-model="dataForm.taxRateExplain"
            size="small"
            :loading="dictLoading"
          >
            <el-option
              v-for="item in dictionary.taxRateExplain"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="cont-tips">
        <p>1.企业性质为小规模纳税人且月销售额&lt;=10万；</p>
        <p>2.只允许编辑尚未开票、开具失败的发票数据；</p>
        <p>3.单次可批量修改[1~100]条符合条件的发票。</p>
        <br />
        <span
          >2023年1月1日至2027年12月31日，月销售额10万元以下(含本数)的小规模纳税人免征增值税，取得的适用3%征收率的应税销售收入，可减按1%征收率征收增值税。您如想享受上述政策，开具普通发票时应选择1%征收率您现在选择3%征收率，是否因为前期已开具3%征收率的发票，发生销售折让、中止或者退回等情形需要开具3%征收率的红字发票或者开票有误需要重新开具3%征收率的发票，请确认。</span
        >
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="default" :loading="loading" @click="handleCancel"
        >取消</el-button
      >
      <el-button type="primary" :loading="loading" @click="handleConfirm"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { reqUpdateTaxRateExplain } from "@/service/sal/invoice.js";

export default {
  name: "SalInvoiceEditTaxRateExplain",
  data() {
    return {
      loading: false,
      visible: false,
      invoices: [],
      dataForm: { salInvoiceIds: [], taxRateExplain: "" },
      dictionary: {
        taxRateExplain: [],
      },
      dictLoading: false,
    };
  },
  async created() {
    await this.initDict();
  },
  methods: {
    // 初始化字典
    async initDict() {
      this.dictLoading = true;
      const { data } = await this.batchGetDicts({
        types: ["sal_invoice_tax_rate_explain"],
      });
      this.dictionary.taxRateExplain = data["sal_invoice_tax_rate_explain"];
      this.dictLoading = false;
    },
    // 编辑弹窗
    showDialog(invoices) {
      if (invoices.length < 1) {
        this.toast("修改失败，没有指定发票数据！", "warning");
        return;
      }

      if (
        invoices.filter(
          (invoice) =>
            ["WSC", "WKP", "KJSB"].indexOf(invoice.invoiceStatus) == -1
        ).length > 0
      ) {
        this.toast("修改失败，包含不符合条件的发票！", "warning");
        return;
      }

      this.invoices = invoices;
      this.dataForm.salInvoiceIds = invoices.map((invoice) => invoice.id);
      this.visible = true;
    },
    // 确定事件
    async handleConfirm() {
      this.loading = true;
      const { success } = await reqUpdateTaxRateExplain(this.dataForm);

      this.loading = false;
      if (success) {
        this.visible = false;
        this.toast("修改成功", "success");
        this.$emit("success");
      }
    },
    // 取消事件
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  padding: 0px 40px 30px 40px;
  text-align: left;

  .el-form-item {
    margin-bottom: 10px;
  }

  .cont-tips {
    padding-top: 24px;

    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }

    p {
      font-size: 14px;
    }
  }
}
</style>