<template>
  <div class="query-wrap">
    <el-row>
      <el-col :span="8">
        <span>发票代码:</span>
        <el-input v-model="invoiceCode" maxlength="12" />
      </el-col>
      <el-col :span="10">
        <span>发票号码:</span>
        <el-input v-model="invoiceNo" maxlength="10" />
      </el-col>
      <el-col :span="6" class="btn-area">
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-button type="default" @click="handleReset">重置</el-button>
      </el-col>
    </el-row>
    <el-table v-loading="tableLoading" :data="tableData" borber stripe style="width: 100%" @row-click="handleRowClick" :header-cell-style="handleHeaderCellStyle">
      <el-table-column type="index" label="序号" width="60" />
      <el-table-column prop="billingType" label="发票类型" :formatter="handleFmt" />
      <el-table-column prop="invoiceCode" label="发票代码" />
      <el-table-column prop="invoiceNo" label="发票号码" />
      <el-table-column prop="buyerTaxNo" show-overflow-tooltip label="购方税号" />
      <el-table-column prop="buyerName" show-overflow-tooltip label="购方名称" />
      <el-table-column prop="amount" label="开票金额" />
      <el-table-column prop="sumAmount" label="价税合计" />
      <el-table-column prop="invoiceTime" :formatter="handleFmt" label="开票日期" />
    </el-table>
    <el-pagination layout="total, sizes, prev, pager, next" :page-sizes="[10, 15, 30, 50]" :current-page.sync="current" :page-size="size" :total="total" @size-change="handleSizeChange" @current-change="handleQuery"></el-pagination>
  </div>
</template>

<script>
import { billList, selectInvoice } from '@/service/sal/invoice.js';
import { tableStyle } from '@/util/mixins';
export default {
  name: 'QueryInvoice',
  mixins: [tableStyle],
  props: ['billingType', 'orgId'],
  data() {
    return {
      invoiceCode: '',
      invoiceNo: '',
      current: 1,
      size: 10,
      total: 0,
      tableData: [],
      tableLoading: false
    };
  },
  methods: {
    // 查询
    async handleQuery() {
      const params = {
        orgId: this.orgId,
        billingFlag: 'Lp', // 只查蓝票
        billingType: this.billingType,
        invoiceNo: this.invoiceNo,
        invoiceCode: this.invoiceCode,
        page: this.current,
        size: this.size
      };
      this.tableLoading = true;
      this.tableData = [];
      const { success, data } = await billList(params);
      this.tableLoading = false;
      if (success) {
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    handleSizeChange(value) {
      this.size = value;
      this.handleQuery();
    },
    // 表格行点击
    async handleRowClick(row) {
      const { success, data } = await selectInvoice(row.id);
      if (success) this.$store.state.invoiceIssue.copyInvoice = data;
      this.$emit('invoice-click');
    },
    // 重置查询条件
    handleReset() {
      this.invoiceCode = '';
      this.invoiceNo = '';
      this.current = 0;
    },
    // 数据格式化
    handleFmt(row, column) {
      let val = row[column.property];
      switch (column.property) {
        case 'billingType':
          return this.handleValueToLabel('BillingType', val);
        case 'invoiceTime':
          return val ? this.$moment(val).format('YYYY-MM-DD') : '--';
        case 'amount':
        case 'sumAmount':
          return val ? `¥${val}` : '';
        default:
          return val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.query-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  margin-top: -30px;

  span {
    margin-right: 8px;
  }
  .el-row {
    margin-bottom: 24px;
  }
  .btn-area {
    text-align: right;
  }
}
</style>
