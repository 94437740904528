<template>
  <el-dialog append-to-body :visible.sync="show" :show-close="false" v-if="show" :close-on-click-modal="false" :close-on-press-escape="false" title="修改发票信息" @close="$emit('update:show', false)" width="500px">
    <div class="invoice-title-form">
      <el-form :model="invoice" ref="invoiceForm" :rules="invoiceRules">
        <div class="edit-select">
          <div class="edit-select-item">
            <el-form-item prop="realEstateCertificate" label="房屋产权证书/不动产权证号">
              <el-input v-model="invoice.realEstateCertificate"></el-input>
            </el-form-item>
            <el-form-item prop="realEstateAddress" label="不动产地址">
              <el-input v-model="invoice.realEstateAddress"></el-input>
            </el-form-item>
          </div>
          <div class="edit-select-item">
            <el-form-item prop="leaseDateStart" label="租赁期起">
              <el-date-picker
                  v-model="invoice.leaseDateStart"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="leaseDateEnd" label="租赁期止">
              <el-date-picker
                  v-model="invoice.leaseDateEnd"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>

          </div>
          <div class="edit-select-item">
            <el-form-item prop="transRegionalMark" label="跨地(市)标识">
              <el-select v-model="invoice.transRegionalMark" clearable placeholder="请选择">
                <el-option label="是" value="Y"></el-option>
                <el-option label="否" value="N"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="transRegionalMark" label="">
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="default" @click="$emit('update:show', false)">取消</el-button>
      <el-button type="primary" @click="handleUpdate">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateInvoice } from '@/service/sal/invoice';

export default {
  name: 'InvoiceEdit',
  props: ['show', 'invoice'],
  data() {
    return {
      invoiceRules: {
        realEstateCertificate: [{ required: true, message: '房屋产权证书/不动产权证号必填', trigger: 'blur' }],
        realEstateAddress: [{ required: true, message: '不动产地址必填', trigger: 'blur' }],
        leaseDateStart: [{ required: true, message: '租赁期起必填', trigger: 'blur' }],
        leaseDateEnd: [{ required: true, message: '租赁期止必填', trigger: 'blur' }],
      }
    };
  },
  methods: {
    handleUpdate() {
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          updateInvoice(this.invoice).then((res) => {
            if (res.success) {
              this.toast('修改成功', 'success', () => {
                this.$emit('update:show', false);
              });
              this.$emit('success');
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.invoice-title-form {
  padding: 0 24px;
}

.edit-select {
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .edit-input .el-input {
      width: 450px;
    }
  }

  .edit-select-foot {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

::v-deep .el-form-item__label {
  padding: 0px 0px 0px 0px;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 64px;
}

::v-deep .el-dialog__body {
  margin-top: 0px;
}
</style>
