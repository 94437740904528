<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm">
          <div>
            <el-form-item label="开票日期" prop="billedRange">
              <el-date-picker
                v-model="billedRange"
                type="daterange"
                range-separator="-"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 226px;padding:0px 10px">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="客户名称" prop="buyerName">
              <el-input v-model.trim="queryForm.buyerName" placeholder="请输入客户名称" clearable
                        maxlength="30" @keyup.enter.native="handleGetInvoiceAlreadyData()"/>
            </el-form-item>

            <el-form-item label="订单编号" prop="dataId">
              <el-input v-model.trim="queryForm.dataId" placeholder="请输入订单编号"
                        maxlength="30" @keyup.enter.native="handleGetInvoiceAlreadyData()"/>
            </el-form-item>
          </div>

          <div v-show="selectExpended">
            <el-form-item label="发票号码" prop="invoiceNo">
              <el-input v-model.trim="queryForm.invoiceNo" placeholder="请输入发票号码"
                        maxlength="12" style="width: 226px" @keyup.enter.native="handleGetInvoiceAlreadyData()"/>
            </el-form-item>

            <el-form-item label="发票类型" prop="billingType">
              <el-select v-model="queryForm.billingType" placeholder="请选择">
                <el-option v-for="item in invoiceType"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="发票状态" prop="invoiceStatus">
              <el-select v-model="queryForm.invoiceStatus" placeholder="请选择">
                <el-option v-for="item in invoiceStatus"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item label="开票人" prop="payer">
              <el-input v-model.trim="queryForm.payer" placeholder="请输入开票人"
                        maxlength="20" class="payer"
                        @keyup.enter.native="handleGetInvoiceAlreadyData()"/>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button
            show-status
            @list-close="handleListClose"
            @list-show="handleListShow"
            @select="handleQuery"
            @reset="handleReset">
          </select-button>
        </div>

      </div>
    </div>
    <div class="content-main">
      <div class="main-top">
        <div style="display: flex;justify-content: flex-start">
          <span>票据列表</span>
          <el-popover
            placement="right"
            width="80"
            trigger="click">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
            </el-checkbox>
            <div class="part"></div>
            <el-checkbox-group v-model="colOptions" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in colSelect" :label="item" :key="item"
                           style="display:block;margin-top: 6px"></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="main-top" style="cursor:pointer">
              <img class="set" src="@/assets/icon/set.png">
              <span style="color: #1890FF">列设置</span>
            </div>
          </el-popover>
        </div>
        <div>
          <el-button type="default" :disabled="selections.length < 1" @click="handleUpdateVersion" style="margin-right: 15px">修改商品编码版本号</el-button>
          <el-button type="default" :disabled="exportDisabled" @click="handleExport" style="margin-right: 15px">导出发票
          </el-button>
          <el-dropdown @command="handlePrintActions">
            <el-button type="primary">
              打印设置<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Invoice">发票设置</el-dropdown-item>
              <el-dropdown-item command="Details">清单设置</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="content-table">
        <el-table border stripe :data="tableData" v-loading="recordsLoading"
                  show-summary :summary-method="getSummaries"
                  :header-cell-style="handleHeaderCellStyle"
                  style="width: 100%"
                  ref="tableDataRef"
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection" fixed="left" prop="id"></el-table-column>
          <el-table-column type="index" label="序号" width="60"/>
          <el-table-column v-if="colData[0].isTrue" prop="dataId" label="订单编号" width="250">
            <template slot-scope="scope">
              <div class="data-id">
                <img v-if="scope.row.dataFlag == 'SGLR'" class="manual-icon"
                     src="@/assets/icon/icon-manual.png">
                <span>{{ scope.row.dataId }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="colData[1].isTrue" prop="extendBizNo" label="确认号" width="250"></el-table-column>
          <el-table-column v-if="colData[2].isTrue" prop="roomId" label="房间号" width="100"/>
          <el-table-column v-if="colData[3].isTrue" prop="buyerName" show-overflow-tooltip label="客户名称" width="220"/>
          <el-table-column v-if="colData[4].isTrue" prop="buyerTaxNo" label="客户税号" width="170"/>
          <el-table-column v-if="colData[5].isTrue" prop="invoiceCode" label="发票代码" width="110"/>
          <el-table-column v-if="colData[6].isTrue" prop="invoiceNo" label="发票号码" width="100"/>
          <el-table-column v-if="colData[7].isTrue" prop="invoiceTime" label="开票日期" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[8].isTrue" prop="sumAmount" label="价税合计" width="100"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[9].isTrue" prop="billingType" label="发票类型" width="110"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[10].isTrue" prop="invoiceStatus" label="发票状态" width="100">
            <template slot-scope="scope">
              <div :class="handleBillingTypeColor(scope.row.invoiceStatus)">
                {{ handleValueToLabel('InvoiceStatus', scope.row.invoiceStatus) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="colData[11].isTrue" prop="failedMessage" show-overflow-tooltip label="失败原因"/>
          <el-table-column v-if="colData[12].isTrue" prop="dataFlag" label="数据来源" width="110"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[13].isTrue" prop="sendMode" label="取票方式" width="120">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  <div>
                    <span>取票方式:</span>
                    <span>{{ handleValueToLabel('BillingSendMode', scope.row.sendMode) }}</span>
                  </div>
                  <div v-if="scope.row.sendMode =='Kd'">
                    <span>取票信息:</span>
                    <span>{{ scope.row.expressContact }}&nbsp;&nbsp;{{
                        scope.row.expressPhone
                      }}&nbsp;&nbsp;{{ scope.row.expressAddress }}</span>
                  </div>
                  <div v-if="scope.row.sendMode =='Dx'">
                    <span>取票信息:</span>
                    <span>{{ scope.row.receivePhone }}</span>
                  </div>
                  <div v-if="scope.row.sendMode =='Yj' ">
                    <span>取票信息:</span>
                    <span>{{ scope.row.receiveEmail }}</span>
                  </div>
                  <div v-if="scope.row.receiveRemark != ''">
                    <span>取票备注:</span>
                    <span>{{ scope.row.receiveRemark }}</span>
                  </div>
                </div>
                <span>{{ handleValueToLabel('BillingSendMode', scope.row.sendMode) }}<span
                  style="color: #09BB07;"
                  v-if="scope.row.sendMode=='Kd'&&scope.row.expressPosted">(已邮寄)</span></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column v-if="colData[14].isTrue" prop="invoicePrintStatus" label="打印状态" width="100"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[15].isTrue" prop="updated" label="更新时间" width="180"></el-table-column>
          <el-table-column v-if="colData[16].isTrue" prop="payer" label="开票人" width="110"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[17].isTrue" prop="operate" label="操作" width="240" fixed="right">
            <template slot-scope="scope">
              <div v-if="scope.row.invoiceStatus === 'KJSB'" class="operate-button">
                <el-button type="text" @click="handleDetailInvoice(scope.row)">
                  详情
                </el-button>
                <el-button type="text" @click="handleInvoiceIssue(scope.row)">
                  开具
                </el-button>
                <el-button type="text" @click="handleInvoiceDelete(scope.row)">
                  删除
                </el-button>
              </div>
              <div class="operate-button" v-else>
                <el-button type="text" @click="handleDetailInvoice(scope.row)">
                  详情
                </el-button>
                <el-button type="text" @click="handelCopyInvoice(scope.row)"
                           :disabled="scope.row.dataFlag == 'CXTS'">
                  复制
                </el-button>
                <el-button type="text"
                           :disabled="handleInvoiceButtonDisabled(1,scope.row)"
                           @click="handleInvoiceTored(scope.row)">
                  冲红
                </el-button>
                <el-button type="text"
                           v-if="scope.row.billingType != 'Dzpp'&&scope.row.billingType != 'DZZP'&&scope.row.billingType != 'SGDP'"
                           :disabled="handleInvoiceButtonDisabled(2,scope.row)"
                           @click="handleInvoiceRevoke(scope.row)">
                  作废
                </el-button>
                <el-button type="text"
                           v-if="scope.row.billingType === 'Dzpp'||scope.row.billingType === 'DZZP'||scope.row.billingType == 'SGDP'"
                           :disabled="handleInvoiceButtonDisabled(4,scope.row)"
                           @click="handleInvoiceDownLoad(scope.row)">
                  下载
                </el-button>
                <el-button type="text"
                           v-if="scope.row.billingType === 'Dzpp'||scope.row.billingType === 'DZZP'||scope.row.billingType == 'SGDP'"
                           :disabled="handleInvoiceButtonDisabled(3,scope.row)"
                           @click="handleDeliver(scope.row)">
                  交付
                </el-button>
                <el-button v-else type="text" :disabled="handleInvoiceButtonDisabled(5,scope.row)"
                           @click="handlePrint(scope.row)">
                  打印
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页组件 -->
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.page"
          :page-sizes="pageSizes"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal">
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <invoice-open-detail :show.sync="drawer" :invoice="invoice"/>
    <!-- 冲红弹窗 -->
    <invoice-tored ref="invoiceTored" :invoice="checkData" @success="handleQuery"/>
    <!-- 作废弹窗 -->
    <invoice-revoke ref="invoiceRevoke" :invoice="checkData" @success="handleQuery"/>
    <!-- 开票弹窗 -->
    <invoice-bill v-if="issueVisible" :show.sync="issueVisible"
                  :invoice="issueData"
                  @invoice-close="handleQuery"
                  @billing-invoice="handleBillingInvoice">
    </invoice-bill>
    <!-- 导出弹窗 -->
    <el-dialog append-to-body
               title="提示"
               :visible.sync="exportToastVisible"
               width="480px"
               close="toastClosed">
      <div class="export-select">
        <span>导出文件格式</span>
        <el-select v-model="queryForm.exportType">
          <el-option value="excel" label="Excel文件"/>
          <el-option value="txt" label="txt文件"/>
        </el-select>
      </div>
      <span class="dialog-message" v-if="selections.length>0">您是否要导出已选中或查询出来的{{selections.length}}张发票数据？</span>
      <span class="dialog-message" v-else>您是否要导出已选中或查询出来的{{queryForm.total}}张发票数据？</span>
      <br/>
      <span style="font-size: 14px; color: #D60303;">注：导出数据时，仅会导出列设置中已选中的字段。</span>
      <span slot="footer" class="dialog-footer">
                <el-button @click="exportToastVisible = false">取 消</el-button>
                <el-button type="primary" @click="exportExcel">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 复制弹窗 -->
    <invoice-copy ref="invoiceCopy" :invoice="checkData" @success="handelCopyInvoiceSuccess"/>
    <!-- 发票删除 -->
    <invoice-delete ref="invoiceDelete" :invoices="deleteInvoices" @success="handleQuery"/>
    <!-- 发票打印 -->
    <invoice-print ref="invoicePrint" :invoices="printInvoices"/>
    <!-- 发票交付 -->
    <invoice-send ref="invoiceSend" :invoices="sendInvoices"/>
    <invoice-item-update-version ref="invoiceItemUpdateVersion" :invoices="iuvInvoices" />
  </div>
</template>

<script>
import {
  billList,
  selectInvoice,
  getInventory,
  rebillInvoice,
  downloadInvoice,

  exportAlreadyInvoices,
} from '@/service/sal/invoice'
import { changeMethods, commonsMethods, dateOption, tableStyle } from '@/util/mixins'
import { HOTEL_RETAIL_ALREADY_LINE, INVOICE_STATUS, OPEN_INVOICE_TYPES } from '@/content/invoice.js'
import InvoiceOpenDetail from '@/components/invoice/InvoiceOpenDetail'
import InvoiceBill from '@/views/invoice-open/components/invoice-bill'
import InvoiceSend from '@/views/invoice-open/components/invoice-send'
import InvoiceCopy from '@/views/invoice-open/components/invoice-copy'
import InvoiceTored from '@/views/invoice-open/components/invoice-tored'
import InvoicePrint from '@/views/invoice-open/components/invoice-print'
import InvoiceRevoke from '@/views/invoice-open/components/invoice-revoke'
import InvoiceDelete from '@/views/invoice-open/components/invoice-delete'
import InvoiceItemUpdateVersion from '@/views/invoice-open/components/invoice-item-update-version'

export default {
  name: 'InvoiceAlready',
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  components: {
    InvoiceBill,
    InvoiceSend,
    InvoiceCopy,
    InvoiceTored,
    InvoicePrint,
    InvoiceRevoke,
    InvoiceDelete,
    InvoiceOpenDetail,
    InvoiceItemUpdateVersion,
  },
  data () {
    return {
      colData: HOTEL_RETAIL_ALREADY_LINE,
      colOptions: [],
      selections: [], // 勾选项
      colSelect: HOTEL_RETAIL_ALREADY_LINE.map(i => i.title),
      selectExpended: false,
      isIndeterminate: true,
      checkAll: false,
      queryForm: {
        buyerName: undefined,
        dataId: undefined,
        invoiceNo: undefined,
        billingType: undefined,
        invoiceStatus: undefined,
        exportType: 'excel',
        colOptions: [],
        page: 1,
        size: 15,
      },
      idList: [],
      billedRange: [],
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100],
      invoiceType: OPEN_INVOICE_TYPES,
      invoiceStatus: INVOICE_STATUS,
      selectStatus: true,
      exportToastVisible: false,
      printVisible: false,
      /*表格数据区*/
      tableData: [],
      /*详情数据区*/
      drawer: false,
      invoice: {},
      /*冲红数据区*/
      invoiceToredVisible: false,
      invoiceRedData: {
        invoiceCode: '',
        invoiceCodeNew: '',
        invoiceNo: '',
        invoiceNoNew: '',
        billingType: '',
        amount: '',
        redNoticeNo: '',
        receiveEmail: ''
      },
      /*作废数据区*/
      obsolete: false,
      checkData: {
        id: '',
        invoiceCode: '',
        invoiceNo: '',
        billingType: '',
        amount: '',
        isDeliver: false,
        receiveEmail: ''
      },
      /*开具数据区*/
      issueVisible: false,
      issueData: {},
      issueDetailVisible: false,
      forceUnlock: false,
      forceUnlockData: {},
      recordsLoading: false,
      copyVisible: false,
      exportDisabled: false,
      /* 发票删除数据区 */
      deleteVisible: false,
      deleteTitle: '是否删除当前信息',
      deleteInvoices: [],
      printInvoices: [],
      sendInvoices: [],
      iuvInvoices: [],
    }
  },
  computed: {
    selectStatusClass: function () {
      return this.selectStatus ? '' : 'row-first'
    },
    selectStatusMessage: function () {
      return this.selectStatus ? '展开' : '收起'
    },
    isShowPrint: function () {
      return this.pintSetUpForm.printWay == 'standard' ? true : false
    }
  },
  created () {
    this.handleGetInvoiceAlreadyData()
    this.handleGetlineSet()
  },
  methods: {
    // 表格勾选
    handleSelectionChange (val) {
      let ids = []
      this.selections = val
    },
    //获取列设置
    handleGetlineSet () {
      if (JSON.parse(localStorage.getItem('hotelLineSet')) != null) {
        this.colData = JSON.parse(localStorage.getItem('hotelLineSet'))
        if (this.colData.filter(i => i.isTrue == true).length === this.colSelect.length) {
          this.checkAll = true
          this.isIndeterminate = false
        }
        this.colOptions = JSON.parse(localStorage.getItem('hotelLineSet')).filter(i => i.isTrue == true).map(item => item.title) //选中
      } else {
        this.colOptions = this.colData.map(item => item.title)//默认所有
        this.checkAll = true
        this.isIndeterminate = false
      }
    },
    //列设置全选
    handleCheckAllChange (val) {
      this.colOptions = val ? this.colSelect : []
      this.isIndeterminate = false
    },
    //列设置单选
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.colSelect.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length
    },
    // 重置筛选列表
    handleReset () {
      this.billedRange = []
      this.resetForm('queryForm')
    },
    // 收起筛选列表
    handleListClose () {
      this.selectExpended = false
    },
    // 展开筛选列表
    handleListShow () {
      this.selectExpended = true
    },
    /* 表格合计 */
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value)) && index === 9) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return Number((prev + curr).toFixed(2))
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' 元'
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    changedSelectStatus () {
      this.selectStatus = !this.selectStatus
    },
    /* 已开发票查询 */
    handleQuery () {
      this.handleGetInvoiceAlreadyData()
    },
    // 复制发票
    handelCopyInvoice (row) {
      this.checkData = row
      setTimeout(() => {
        this.$refs.invoiceCopy.open()
      }, 0)
    },
    handelCopyInvoiceSuccess (invoice) {
      this.$emit('changeManual')
    },
    /* 已开发票数据 */
    handleGetInvoiceAlreadyData () {
      this.recordsLoading = true
      //查询 清空选择项
      this.queryForm.ids = []
      billList(this.addDateRange(this.queryForm, this.billedRange, 'Billed')).then(({ success, data }) => {
        this.recordsLoading = false
        if (success) {
          this.tableData = data.records
          this.dataTotal = data.total
        }
      }).catch(() => {
        this.recordsLoading = false
      })
    },
    /* 已开发票记录导出 */
    handleExport () {
      this.exportToastVisible = true
    },
    toastClosed () {
      this.exportToastVisible = false
    },
    async exportExcel () {
      this.exportToastVisible = false
      this.exportDisabled = true
      let items = this.selections
      this.idList = items.map(item => item.id)
      this.queryForm.colOptions = this.colOptions
      //导出参数与 查询参数分割
      let param =  this.queryForm;
      param.ids =  this.idList
      const rsp = await exportAlreadyInvoices(param)
      this.exportDisabled = false
      this.downloadFile(rsp)
    },
    /* 按钮可用/禁用 */
    handleInvoiceButtonDisabled (type, params) {
      switch (type) {
        case 1: // 冲红
          if (params.billingType == 'Dzpp' || params.billingType == 'DZZP' || params.billingType == 'SGDP') {
            if (params.sumAmount > 0 && params.invoiceStatus == 'YKJ') {
              return false
            }
          } else {
            if (params.invoiceStatus == 'YKJ' && this.dateFormat(new Date().toLocaleString(),'YYYY-MM') > this.dateFormat(params.invoiceTime,'YYYY-MM')) {
              return false
            }
          }
          return true
        case 2: // 作废
          if (['BW-XML-SKP', 'HX-XML-JSP'].indexOf(params.billingChannel) > -1) return true

          return new Date().getMonth() > new Date(params.invoiceTime).getMonth()
            || params.billingType == 'Dzpp'
            || params.billingType == 'DZZP'
            || (params.invoiceStatus != 'YKJ'
              && params.invoiceStatus != 'ZFSB')
        case 3: // 交付
          return params.invoiceStatus == 'KJZ' || params.invoiceStatus == 'CHZ' || params.invoiceStatus == 'ZFZ'
        case 4: // 下载
          return !(params.invoiceStatus == 'YKJ' || params.invoiceStatus == 'YCH')
        case 5: // 打印
          if (['BW-XML-SKP', 'HX-XML-JSP'].indexOf(params.billingChannel) > -1) return true

          return !(params.invoiceStatus == 'YKJ' || params.invoiceStatus == 'ZFSB' || params.invoiceStatus == 'YZF' || params.invoiceStatus == 'YCH' || params.invoiceStatus == 'CHSB')
        default:
          return false
      }
    },
    /* 发票详情 */
    handleDetailInvoice (invoice) {
      this.drawer = !this.drawer
      selectInvoice(invoice.id).then(res => {
        if (res.success) {
          this.invoice = res.data
          this.invoice.industry = 'hotel'
          this.invoice.items.forEach(item => item.taxRate = Number(item.taxRate) * 100 + '%')
          // 统计信息
          let sumAmount = this.invoice.items.map(item => Number(item.amount)).reduce((sum, amount) => sum + amount, 0)
          let sumTaxAmount = this.invoice.items.map(item => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0)
          let total = {
            merchandiseName: '合计',
            amount: Number(sumAmount).toFixed(2),
            taxAmount: Number(sumTaxAmount).toFixed(2),
          }
          this.invoice.items.push(total)
          let sum = Number(sumAmount + sumTaxAmount).toFixed(2)
          let sumBig = this.handleSmallToCapitalize(sum)
          let priceTax = {
            merchandiseName: '价税合计（大写）',
            specification: sumBig,
            unit: '（小写）￥' + sum,
          }
          this.invoice.items.push(priceTax)
          this.invoice.sendBackInfo = res.data
        }
      })
    },
    // 发票冲红
    handleInvoiceTored (invoice) {
      this.checkData = invoice
      setTimeout(() => {
        this.$refs.invoiceTored.open()
      }, 0)
    },
    // 发票作废
    handleInvoiceRevoke (invoice) {
      this.checkData = invoice
      setTimeout(() => {
        this.$refs.invoiceRevoke.open()
      }, 0)
    },
    // 发票下载
    async handleInvoiceDownLoad (row) {
      if (['BW-XML-SKP', 'HX-XML-JSP'].indexOf(row.billingChannel) > -1) {
        this.toast('该票是百旺/航信-XML开具，请在开票软件中下载！', 'warning')
        return
      }

      const rsp = await downloadInvoice(row.id)
      this.downloadFile(rsp)
    },
    // 发票打印
    handlePrint (row) {
      this.printInvoices.splice(0, this.printInvoices.length, row)
      setTimeout(() => {
        this.$refs.invoicePrint.openPrint()
      }, 0)
    },
    handlePrintActions (flag) {
      this.$refs.invoicePrint.openSetup(flag)
    },
    handleUpdateVersion(){
      this.iuvInvoices.splice(0, this.iuvInvoices.length, ...this.selections)
      this.$refs.invoiceItemUpdateVersion.open();
    },
    // 发票交付
    handleDeliver (row) {
      this.sendInvoices.splice(0, this.sendInvoices.length, row)
      setTimeout(() => {
        this.$refs.invoiceSend.open()
      }, 0)
    },
    /* 发票开具 */
    handleInvoiceIssue (invoice) {
      this.issueVisible = true
      this.issueData = invoice
    },
    async handleBillingInvoice (printable, back) {
      const { success } = await rebillInvoice(this.issueData.id)
      if (success) {
        if (back) back(success)
      }
      this.issueVisible = false
    },
    /* 表格值格式化 */
    handleTableValueFormat (row, column) {
      let value = row[column.property]
      switch (column.label) {
        case '开票人':
          if (value != null && value != '') {
            return value
          }
          return '--'
        case '开票日期':
          if (value == undefined) {
            return ''
          }
          return this.$moment(value).format('YYYY-MM-DD')
        case '发票类型':
          return this.handleValueToLabel('BillingType', value)
        case '价税合计':
          return '￥' + value
        case '数据来源':
          return this.handleValueToLabel('DataFlag', value)
        case '取票方式':
          return this.handleValueToLabel('BillingSendMode', value)
        case '打印状态':
          return row.billingType == 'Dzpp' ? '--' : this.handleValueToLabel('PrintingStatus', value)
        default:
          return value
      }
    },
    /* 发票状态颜色 */
    handleBillingTypeColor (billingType) {
      switch (billingType) {
        case 'KJSB':
        case 'YCH':
        case 'YZF':
          return 'status-color'
        default:
          return ''
      }
    },
    /* 切换分页条数 */
    handleSizeChange (value) {
      this.queryForm.size = value
      this.handleGetInvoiceAlreadyData()
    },
    /* 翻页 */
    handleCurrentChange (value) {
      this.queryForm.page = value
      this.handleGetInvoiceAlreadyData()
    },
    /* 发票删除 */
    handleInvoiceDelete (invoice) {
      this.deleteInvoices = [invoice]
      setTimeout(() => {
        this.$refs.invoiceDelete.open()
      }, 0)
    },
    fmtBillingType (val) {
      return this.handleValueToLabel('BillingType', val)
    }
  },
  watch: {
    colOptions (valArr) {
      const arr = this.colSelect.filter(i => valArr.indexOf(i) < 0) // 未选中
      this.colData.filter(i => {
        if (arr.indexOf(i.title) != -1) {
          i.isTrue = false
          this.$nextTick(() => {
            this.$refs.tableDataRef.doLayout()
          })
        } else {
          i.isTrue = true
          this.$nextTick(() => {
            this.$refs.tableDataRef.doLayout()
          })
        }
      })
      localStorage.setItem('hotelLineSet', JSON.stringify(this.colData))
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "@/style/select.scss";

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    .set {
      width: 20px;
      height: 20px;
      margin-left: 14px;
      margin-right: 2px;
    }
  }

  .content-select {
    .select-body {
      padding: 24px 24px 24px 24px;
    }

    .select-status {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      color: #3d94ff;
    }

    .select-status:hover {
      cursor: pointer;
    }
  }

  .content-main {
    min-height: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .data-id {
      text-align: left;
    }

    .manual-icon {
      vertical-align: middle;
      margin-right: 4px;
      height: 16px;
      width: 16px;
    }

    .status-color {
      color: #F5222D;
    }

    .operate-button {
      display: flex;
      justify-content: flex-start;

      .el-button {
        padding: 0;
        margin: 0 0 0 10px;
      }

      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
        margin-left: 10px;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }

  .dialog-body {
    margin: -16px 24px 0 24px;

    .body-top {
      .top-first-row {
        display: flex;
        margin-bottom: 12px;
      }

      .top-second-row {
        display: flex;
        text-align: left;
        margin-bottom: 24px;

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 20em;
        }
      }

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }

    .body-bottom {
      text-align: left;
      border-top: 1px solid #E9E9E9;
      margin: 0 -24px;
      padding: 24px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
      }
    }
  }

  .el-dialog__footer {
    padding: 10px 24px 24px;
    text-align: right;
    box-sizing: border-box;
  }

  .dialog-message {
    padding-left: 20px;
    text-align: left;
  }

  .copy-dialog {
    margin-top: -20px;
    padding: 0 20px;

    .copy-title {
      color: #F5222D;
    }
  }

  .print-dialog {
    margin-top: -30px;
    padding: 0 24px;
    font-size: 14px;
    text-align: left;

    .print-content {
      border-bottom: 1px solid #E5E5E5;
      padding: 24px 0;

      div {
        padding-bottom: 12px;
      }

      span {
        color: #333333;
      }
    }

    .tip {
      color: #F5222D;
    }

    .print-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 24px;
    }
  }

  .print-config {
    margin-top: -30px;
    padding: 0 24px;
    text-align: left;

    ::v-deep .el-input__inner {
      width: 232px;
    }

    .print-select {
      ::v-deep .el-input__inner, ::v-deep .el-select, ::v-deep .el-input {
        width: 300px;
      }
    }

  }

  .payer {
    width: 226px;
    margin-left: 14px;
  }

  .export-select {
    margin-bottom: 24px;

    span {
      margin-right: 8px;
    }
  }
</style>
