<template>
  <div v-if="!isImported" class="dialog-wrap">
    <div class="form-item">
      <span class="form-item-name">文件类型：</span>
      <div class="form-item-prop">
        <el-select v-model="importArg.tmplType" @change="handleTmplTypeChange">
          <el-option value="zdy-xls" label="自定义电子表格(Excel即后缀名为xls、xlsx)" />
          <el-option value="yl-tsv" label="医疗文本文件（即后缀名为tsv、csv、txt）" />
          <el-option value="yn-xls" label="雨诺ERP电子表格(Excel即后缀名为xls、xlsx)" />
        </el-select>
        <br />
        <span class="form-item-tips">根据导入数据文件选择对应的发票"导入模板"。</span>
      </div>
    </div>
    <div v-if="importArg.tmplType == 'yl-tsv'" class="form-item">
      <span class="form-item-name">开票类型：</span>
      <div class="form-item-prop">
        <el-select v-model="importArg.billingType" style="width: 300px !important">
          <el-option value="ZZPP" label="增值税普通发票" />
          <el-option value="DZPP" label="增值税电子普票" />
          <el-option value="ZZZP" label="增值税专用发票" />
          <el-option value="QDPP" label="电子发票(普通发票)" />
          <el-option value="QDZP" label="电子发票(增值税专用发票)" />
        </el-select>
      </div>
    </div>
    <div class="form-item">
      <span class="form-item-name">导入文件：</span>
      <div class="form-item-prop">
        <el-upload accept=".xls,.xlsx,.tsv" :action="uploadUrl" :multiple="false" :auto-upload="false" :show-file-list="false" :on-exceed="handleExceed" :on-change="handleUpload" style="display: inline-block">
          <el-input v-model="importArg.fileName" placeholder="文件名称..." style="width: 300px"></el-input>
          <el-button type="primary" plain icon="el-icon-upload2">选择</el-button>
        </el-upload>
      </div>
    </div>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="高级选项" name="1">
        <div class="form-item">
          <span class="form-item-name">自动拆分：</span>
          <div class="form-item-prop">
            <el-select v-model="importArg.autoSplit">
              <el-option value="Y" label="是" />
              <el-option value="N" label="否" />
            </el-select>
            <br />
            <span class="form-item-tips">设置发票是否需要进行"限额和限行"自动拆分。</span>
          </div>
        </div>
        <div class="form-item">
          <span class="form-item-name">自动合并：</span>
          <div class="form-item-prop">
            <el-select v-model="importArg.autoMerge">
              <el-option value="Y" label="是" />
              <el-option value="N" label="否" />
            </el-select>
            <br />
            <span class="form-item-tips">设置发票明细是否按"明细合并规则"进行合并。</span>
          </div>
        </div>
        <div class="form-item">
          <span class="form-item-name">检查商品：</span>
          <div class="form-item-prop">
            <el-select v-model="importArg.checkMerchandise">
              <el-option value="Y" label="是" />
              <el-option value="N" label="否" />
            </el-select>
            <br />
            <span class="form-item-tips">根据"商品编码"校验商品是否已经在系统中管理。</span>
          </div>
        </div>
        <div class="form-item">
          <span class="form-item-name">保存商品：</span>
          <div class="form-item-prop">
            <el-select v-model="importArg.syncMerchandise">
              <el-option value="Y" label="是" />
              <el-option value="N" label="否" />
            </el-select>
            <br />
            <span class="form-item-tips">根据"商品编码"判断并且自动保存新的商品信息。</span>
          </div>
        </div>
        <div class="form-item">
          <span class="form-item-name">折扣均摊：</span>
          <div class="form-item-prop">
            <el-select v-model="importArg.isAutoOffsetNegativeItem">
              <el-option value="Y" label="是" />
              <el-option value="N" label="否" />
            </el-select>
            <br />
            <span style="color: red" class="form-item-tips">折扣金额汇总值不能超过金额汇总值，在开票配置中设置的百分比。</span>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="模板下载" name="2">
        <div class="tmpl-cont" style="display: flex; font-size: 14px; text-align: left">
          <div style="padding-inline-start: 80px; margin-block-end: 0em">
            <a :href="staticFile.invoiceImportTsv.url" :download="staticFile.invoiceImportTsv.name">{{ staticFile.invoiceImportTsv.name }}</a>
            <br />
            <a :href="staticFile.invoiceImportXls.url" :download="staticFile.invoiceImportXls.name">{{ staticFile.invoiceImportXls.name }}</a>
            <br />
            <a :href="staticFile.yuNuoImportXls.url" :download="staticFile.yuNuoImportXls.name">{{ staticFile.yuNuoImportXls.name }}</a>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item v-if="importArg.tmplType == 'zdy-xls'" title="导入说明" name="3">
        <div class="tmpl-cont" style="display: flex; font-size: 14px; text-align: left">
          <ul style="padding-inline-start: 80px; margin-block-end: 0em">
            <li>1.下载批量开票模版，仔细阅读模板规则；</li>
            <li>2.打开模版，填入发票信息，上限20000条；</li>
            <li>3.选择好的发票数据文件，点击导入上传系统；</li>
            <li>4.点击"开具/批量开票"按钮即可提交开票请求。</li>
          </ul>
        </div>
      </el-collapse-item>
    </el-collapse>
    <!-- <div v-if="importArg.tmplType =='zdy-xls'" class="tip">
      <span>
        注：商品前的订单编号一致时，默认开在同一张发票上。<br/><br/>
        <b>发票批量导入模板(Excel)已经升级【2023-04-15】，系统不再支持历史模板格式文件，请您下载后并按规则填入数据完成导入发票操作。</b>
      </span>
    </div> -->
    <div class="dialog-footer">
      <el-button type="default" @click="handleCancle">取消</el-button>
      <el-button type="primary" :disabled="importBtnDisabled" @click="handleImport">导入</el-button>
    </div>
  </div>
  <div v-else class="dialog-wrap">
    <ul>
      <li>导入开票处理数据{{ importRes.success ? '成功' : '失败' }}！</li>
      <li v-if="importRes.success">
        已成功导入<span style="color: #f5222d">{{ importRes.successCnt }}</span
        >条数据，已忽略<span style="color: #f5222d">{{ importRes.ignoredCnt }}</span
        >条无效数据！
      </li>
      <li v-else>
        上传数据有<span style="color: #f5222d">{{ importRes.erroredCnt }}</span
        >条数据不符合规则，请检查修改后重新导入！
      </li>
    </ul>
    <div v-if="importRes.success" class="dialog-footer">
      <el-button type="primary" @click="handleCancle">关闭</el-button>
    </div>
    <div v-else class="dialog-footer">
      <el-button type="warning" @click="handleDownload">下载错误信息</el-button>
      <el-button type="primary" @click="handleUploadAgain">重新选择文件</el-button>
    </div>
  </div>
</template>

<script>
import { invoiceUploadBatch } from '@/service/invoice-open';
import StaticFile from '@/assets/js/static-file';
import { baseURL } from '@/service/index';

export default {
  name: 'InvoiceUploadBatch',
  data() {
    return {
      activeNames: 1,
      staticFile: StaticFile,
      isImported: false,
      importArg: {
        fileName: '',
        fileCont: undefined,
        tmplType: 'zdy-xls',
        autoSplit: 'Y',
        autoMerge: 'Y',
        billingType: 'ZZPP',
        checkMerchandise: 'Y',
        syncMerchandise: 'N',
        isAutoOffsetNegativeItem: 'N'
      },
      importRes: {
        success: true,
        successCnt: 0,
        erroredCnt: 0,
        erroredUrl: '',
        ignoredCnt: 0
      },
      importBtnDisabled: false,
      uploadUrl: `${baseURL}/sal/ori/trade/item/import`
    };
  },
  methods: {
    open() {
      this.downloadVisible = true
    },
    handleCancel(e) {
      this.downloadVisible = false
      console.log(e);
    },
    // 上传错误捕获
    handleExceed() {
      this.toast('导入失败，上传文件发生错误！', 'error');
    },
    // 文件上传成功
    handleUpload(file) {
      this.importArg.fileName = file.name;
      this.importArg.fileCont = file.raw;
    },
    handleTmplTypeChange(val) {
      switch (val) {
        case 'yn-xls':
          this.importArg.billingType = '';
          this.importArg.autoMerge = 'N';
          this.importArg.autoSplit = 'Y';
          this.importArg.checkMerchandise = 'N';
          this.importArg.syncMerchandise = 'Y';
          break;
        case 'yl-tsv':
          this.importArg.billingType = 'ZZPP';
          this.importArg.autoMerge = 'Y';
          this.importArg.autoSplit = 'Y';
          this.importArg.checkMerchandise = 'Y';
          this.importArg.syncMerchandise = 'N';
          break;
        case 'zdy-xls':
        default:
          this.importArg.billingType = '';
          this.importArg.autoMerge = 'Y';
          this.importArg.autoSplit = 'Y';
          this.importArg.checkMerchandise = 'Y';
          this.importArg.syncMerchandise = 'N';
          break;
      }
      this.importArg.isAutoOffsetNegativeItem = 'N';
    },
    // 点击导入
    async handleImport() {
      const args = this.importArg;
      let fileName = args.fileName;
      if (fileName == '') {
        this.toast('导入失败，请先选择导入文件！', 'warning');
        this.importBtnDisabled = false;
        return;
      }
      const index = fileName.match(/\./).index + 1;
      const fileType = fileName.substring(index, fileName.length);
      // tsv文件校验
      if (args.tmplType === 'yl-tsv') {
        if (fileType !== 'tsv') {
          this.toast('导入失败，所选文件格式错误!', 'warning');
          this.importBtnDisabled = false;
          return;
        }
        if (args.billingType === '') {
          this.toast('导入失败，开具类型不可为空！', 'warning');
          this.importBtnDisabled = false;
          return;
        }
      }
      let formData = new FormData();
      for (let item in args) {
        formData.append(item, args[item]);
      }
      this.importBtnDisabled = true;
      const { success, data } = await invoiceUploadBatch(formData);
      if (success) {
        this.importRes = data;
        this.isImported = true;
      }
      this.importBtnDisabled = false;
    },
    // 点击取消
    handleCancle() {
      this.$emit('close');
    },
    // 下载
    handleDownload() {
      let prefix = 'https://oss.dotax.cn/';
      let origin = 'http://collectticket-files-pro.oss-cn-zhangjiakou.aliyuncs.com/';
      let fileUrl = this.importRes.erroredUrl.replace(origin, prefix);
      const a = document.createElement('a');
      a.setAttribute('download', '');
      a.setAttribute('href', fileUrl);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    // 点击再次导入
    handleUploadAgain() {
      this.importArg.fileName = '';
      this.importArg.fileCont = undefined;

      this.importRes = {
        success: true,
        successCnt: 0,
        erroredCnt: 0,
        erroredUrl: '',
        ignoredCnt: 0
      };
      this.isImported = false;
    }
  }
};
</script>

<style scoped lang="scss">
.tmpl-cont {
  padding: 0px 20px 20px 20px;

  a,
  a:hover {
    color: #3d94ff;
    font-size: 16px;
    font-weight: 400;
    line-height: 2em;
    text-decoration: underline;
  }
}

.dialog-wrap {
  color: #333333;
  font-size: 14px;
  margin-top: -36px;

  .title {
    font-size: 18px;
    text-align: left;
    padding-left: 24px;
    margin-bottom: 24px;
  }

  .form-item {
    text-align: left;
    padding-left: 24px;
    padding-bottom: 24px;

    .form-item-name {
      margin-right: 8px;
    }

    .el-select {
      ::v-deep .el-input {
        width: 300px !important;
      }
    }

    .form-item-prop {
      display: inline-table;
    }

    .form-item-tips {
      font-size: 12px;
      margin-top: 5px;
      display: inline-block;
      color: #909090;
    }
  }

  .el-button {
    padding: 0;
    width: 106px;
    margin-left: 10px;
    text-align: center;
  }

  .tip {
    color: #f5222d;
    padding: 24px 26px;
    margin-top: 24px;
    text-align: center;
    border-top: 1px solid #e9e9e9;
  }

  .dialog-footer {
    text-align: right;
    padding: 24px 24px;
  }
}

ul {
  margin-top: 0;
  list-style: none;
  text-align: left;

  li {
    padding-bottom: 12px;
  }
}

.el-collapse {
  margin: 0px 30px 24px 30px;
}
</style>
