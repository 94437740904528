import { get, put } from './index'

/**
 * 分页查询明细
 */
export const getOriginalOrderListByPage = param => {
  return get('/sal/ori/trade/item/list', param).then(res => res)
}

/**
 * 查询明细汇总
 */
export const getOriginalOrderStatistics = param => {
  return get('/sal/ori/trade/item/summary',param).then(res => res)
}

/**
 * 修改发票类型
 */
export const updateBillingType = data => {
  return put('/sal/ori/trade/item/billingType', data).then(res => res)
}

/**
 * 重新匹配明细
 */
export const refresh = data => {
  return put('/sal/ori/trade/item/rematch', data).then(res => res)
}

/**
 * 查询明细汇总
 */
export const getBillingTradeStatistics = (param) => {
  return get('/sal/ori/trade/item/summary', param).then(res => res)
}

/**
 * 明细生成发票
 */
export const generateBillingTrade = param => {
  return put('/sal/ori/trade/item/generate-invoice', param).then(res => res)
}
