<template>
  <div class="order_manual_invoice_view">
    <el-dialog :visible.sync="visiable" width="1240px" :before-close="handleDialog" :close-on-click-modal="false">
      <div class="cus_dialog_tit" slot="title">
        <h2>订单详情</h2>
        <div v-if="!orderState" class="order_status">
          <label>状态：</label>
          <span>此订单已开票，不允许重复开票</span>
        </div>
      </div>
      <div class="con">
        <el-form :inline="true" class="order_seach_form" label-position="left">
          <el-form-item label="订单编号" label-width="80px">
            <el-input type="text" v-model="orderNo" placeholder="请输入订单编号" maxlength="20" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <div class="data_from">数据来源：手工</div>
          </el-form-item>
        </el-form>
        <InvoiceOpenGoodsList v-if="visiable" ref="invoiceGoodsList" :tax-status="hadTax" :goods-list="goodsLists" :biz-type="redType ? 'manual' : 'red'" :goods-index="goodsIndex" @handleFocusTd="handleFocusTd" @handleInsertMerchandise="handleInsertMerchandise" @handleDiscount="handleDiscount" @changeIndex="changeIndex" @dataSelect="handleCurrentGoods" @handleShowGoods="handleShowGoods" @nameBlur="handleMerchandiseNameBlur" @reviewInvoice="handleReviewInvoice" />
        <div v-if="!orderState" class="order_invoiced_mask_box">
          <div class="order_invoiced_mask"></div>
          <i class="el-icon-circle-close" @click="handleDialog('close')"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDialog('close')">取 消</el-button>
        <el-button type="primary" @click="handleDialog('sure')" :disabled="!orderState">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog append-to-body title="选择商品" :visible.sync="dialogGoodsListVisible" width="804px" :show-close="false">
      <merchandise-list-dialog v-if="dialogGoodsListVisible" @handleCurrentGoods="handleCurrentGoods"></merchandise-list-dialog>
    </el-dialog>
    <el-dialog append-to-body title="确认交付发票信息" :visible.sync="deliveryVisible" width="420px" :show-close="false">
      <ul class="invocie_info_list">
        <li class="invocie_info_item"><label>发票类型：</label><span>电子普票</span></li>
        <li class="invocie_info_item"><label>发票代码：</label><span>12345678</span></li>
        <li class="invocie_info_item"><label>发票号码：</label><span>86671675</span></li>
        <li class="invocie_info_item"><label>发票金额：</label><span>¥-178</span></li>
        <li class="invocie_info_item"><label>发票税额：</label><span>¥-20.48</span></li>
      </ul>
      <el-divider></el-divider>
      <div class="deliver_form">
        <el-form :model="form" label-width="80px">
          <el-form-item label="交付邮箱">
            <el-input type="email" v-model="form.email" placeholder="请输入邮箱地址"></el-input>
          </el-form-item>
          <el-form-item label="交付短信">
            <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 如果是不允许重复开票，此处按钮隐藏 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliveryVisible = false">取消</el-button>
        <el-button type="primary" @click="deliveryDialog" :disabled="isDeliveryBtn">交付</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import InvoiceOpenGoodsList from './RetailInvoiceOpenGoodsList';
import MerchandiseListDialog from '../../components/MerchandiseListDialog.vue';
import {DataUtil} from '@/util/dataUtil';
import RegexLib from '@/assets/js/regex-lib';

export default {
  components: { InvoiceOpenGoodsList, MerchandiseListDialog },
  props: {
    visiable: {
      type: Boolean,
      default: false
    },
    buyerInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    invoiceInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    order: {
      type: Object,
      default: () => {
        return {};
      }
    },
    deleteIndex: {
      type: Number,
      default: 0
    },
    hadTax: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        email: '',
        phone: ''
      },
      deliveryVisible: false,
      isDeliveryBtn: true,
      dialogGoodsListVisible: false,
      // hadTax: true, // 含税状态
      redType: true, //  切换红蓝票
      goodsIndex: 0, //商品标识符
      currentGoodsIndex: 0, //当前选中商品标识符
      hadTdFocus: false, // 表格被选中状态
      discountStatus: false, // 折扣状态
      invoicePrice: {}, //发票金额相关
      merchandise: {
        orgId: this.$store.state.invoiceIssue.CurrentOrganization,
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxClassName: '',
        taxClassCode: '',
        taxRateModel: ''
      },
      orderNo: '',
      goodsLists: [],
      orderState: false
    };
  },
  // computed: {
  //   // 含税按钮状态
  //   typeByTaxStatus() {
  //     return this.hadTax ? 'default' : 'primary';
  //   }
  // },
  watch: {
    'form.email': function (val) {
      this.isDeliveryBtn = !val;
    },
    'form.phone': function (val) {
      this.isDeliveryBtn = !val;
    },
    'visiable': {
      immediate: true,
      deep: true,
      handler() {
        this.orderNo = this.order.no
        this.orderState = this.order.status
        this.goodsLists = this.order.items || []
      }
    },
    deleteIndex: function (val) {
      if (val) {
        this.goodsLists = this.goodsLists.filter((item) => item.index != val);
        // if (this.goodsLists.length == 0) {
          // this.$emit('deleteOrder',this.order.no);
        // }
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    deliveryDialog() {
      if (!this.form.email) {
        this.toast(`请输入邮箱地址`, 'warning');
        return;
      } else if (this.form.email && !RegexLib.Email.test(this.form.email)) {
        this.toast('填写的邮箱格式不正确！', 'warning');
        return;
      } else if (!this.form.phone) {
        this.toast(`请输入手机号`, 'warning');
        return;
      } else if (this.form.phone && !RegexLib.Phone.test(this.form.phone)) {
        this.toast('填写的手机号格式不正确！', 'warning');
        return;
      }
    },
    // 开票信息校验
    checkInvoiceInfo() {
      // 购方校验
      if (!this.buyerInfo.buyerName) {
        this.toast(`${/SG/.test(this.invoiceInfo.billingType) ? '销' : '购'}方名称不可为空`, 'error');
        return false;
      }
      if (this.invoiceInfo.billingType == 'Zzzp' || this.invoiceInfo.billingType == 'DZZP') {
        if (!(this.buyerInfo.buyerName && this.buyerInfo.buyerTaxNo && this.buyerInfo.buyerAddressTel && this.buyerInfo.buyerBankAccount)) {
          this.toast('当前开票种类必须将“名称/税号/地址电话/开户行及账号”填写完整', 'error');
          return false;
        }
      }
      if (this.invoiceInfo.billingType == 'QDZP') {
        if (!(this.buyerInfo.buyerName && this.buyerInfo.buyerTaxNo)) {
          this.toast('当前开票种类必须将“名称/税号”填写完整', 'error');
          return false;
        }
      }
      // 开票人信息校验
      if (this.invoiceInfo.billingType == 'QDPP' || this.invoiceInfo.billingType == 'QDZP') {
        if (!(this.buyerInfo.buyerName && this.invoiceInfo.payer)) {
          this.toast('请将“开票人”填写完整', 'error');
          return false;
        }
      } else {
        if (!(this.buyerInfo.buyerName && this.invoiceInfo.payee && this.invoiceInfo.payer && this.invoiceInfo.auditor)) {
          this.toast('请将“开票人/审核人/复核人”填写完整', 'error');
          return false;
        }
      }
      // 航信组件金税盘 - 乐税机柜UKEY - 铭柏机柜UKEY
      if (/SG/.test(this.invoiceInfo.billingType) && ['HX-ZJ-JSP', 'LS-JG-UKEY', 'MB-JG-UKEY'].indexOf(this.invoiceInfo.billingChannel) > 0) {
        this.toast('当前开票设备不支持开具收购发票，请重新选择', 'error');
        return false;
      }
      // 明细行校验
      if (this.goodsLists.length > 0) {
        const amount = this.goodsLists.findIndex((i) => i.sumAmount == '' || i.sumAmount == 0 || i.sumAmount == null);
        if (amount > -1) {
          this.toast(`第${amount + 1}条明细价税合计为空`, 'error');
          return false;
        }
        const index = this.goodsLists.findIndex((i) => i.taxClassCode == '' || i.taxClassCode == null);
        if (index > -1) {
          this.toast(`第${index + 1}条明细税收分类编码为空`, 'error');
          return false;
        }
        const i_index = this.goodsLists.length && this.goodsLists.find((i) => i.price == '' || i.price == 0);
        if (i_index > -1 && (this.goodsLists[i_index].quantity != '' || this.goodsLists[i_index].quantity != 0)) {
          this.toast(`第${i_index + 1}条明细中商品单价为空时，商品数量也必须为空`, 'error');
          return false;
        }
      } else {
        this.toast('至少录入一行商品明细', 'error');
        return false;
      }
      // 红票校验
      if (!this.redType) {
        if (!this.invoiceInfo.originInvoiceCode) {
          this.toast('开具红票原始发票代码不可为空', 'error');
          return false;
        }
        if (!this.invoiceInfo.originInvoiceNo) {
          this.toast('开具红票原始发票号码不可为空', 'error');
          return false;
        }
        if (this.invoiceInfo.billingType == 'Zzzp' && !this.invoiceInfo.redNoticeNo) {
          this.toast('开具红字专票, 红字信息表编号不可为空', 'error');
          return false;
        }
      }
      return true;
    },
    // 发票价税补全
    handleReviewInvoice(invoicePrice) {
      this.invoicePrice = invoicePrice;
    },
    // 输入货物清单通知
    handleMerchandiseNameBlur(row, merchandises) {
      console.log(row, merchandises);
      //  失去焦点行下标
      this.currentGoodsIndex = row.index;
      if (!row.taxClassCode) {
        this.handleInsertMerchandise(DataUtil.clone(row));
        return;
      }
      this.handleCurrentGoods(row);
    },
    // 展开商品选择弹窗
    handleShowGoods(goods) {
      this.dialogGoodsListVisible = true;
      this.currentGoodsIndex = goods.index;
    },
    // 商品选中
    handleCurrentGoods(row) {
      let goods = this.goodsLists && this.goodsLists.find((i) => i.index == this.currentGoodsIndex);
      if (!goods) return;

      if (row.specification) {
        goods.specification = row.specification;
      }
      if (row.unit) {
        goods.unit = row.unit;
      }
      // 免税-不征税明细
      if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
        goods['taxRateModel'] = row.taxPreferentialContent;
        goods['taxRate'] = 0;
      } else {
        goods['taxRate'] = Number(row.taxRate);
        goods['taxRateModel'] = Number(row.taxRate);
      }
      goods.price = row.price == 0 ? null : row.price;
      goods.uniqueCode = row.uniqueCode;
      goods.taxClassCode = row.taxClassCode;

      // 显示值
      if (!row.merchandiseName && row.untaxMerchandiseName) {
        row.merchandiseName = row.untaxMerchandiseName;
      }
      goods.merchandiseName = /^\*/.test(row.merchandiseName) ? row.merchandiseName : `*${row.taxClassName}*${row.merchandiseName}`;
      goods.notFirst = true;
      goods.taxClassName = row.taxClassName;
      goods.taxVersionNo = row.taxVersionNo;
      goods.isTaxPreferential = row.isTaxPreferential;
      goods.taxPreferentialContent = row.taxPreferentialContent;
      goods.priceVariable = row.priceVariable;
      goods.visible = false;

      this.$refs.invoiceGoodsList.handleKeyupGoodsPrice(goods);
      this.dialogGoodsListVisible = false;
      let inputDom = document.getElementById('input-' + row.index);
      inputDom ? inputDom.blur() : null;

      // 焦点移动
      setTimeout(() => this.$refs.invoiceGoodsList.focusSpec(goods.index), 0);
    },
    // 商品标识符
    changeIndex(value) {
      this.goodsIndex += value;
    },
    // 显示折扣相关弹窗
    handleDiscount() {
      let goods = this.goodsLists.length && this.goodsLists.find((i) => i.index == this.currentGoodsIndex);
      this.discountForm = { ...this.discountForm, ...goods };
      this.deleteGoods = goods.itemProperty == '2';
      this.discountStatus ? (this.deletedDiscountVisible = true) : (this.insertDiscountVisible = true);
    },
    // 新增商品信息弹窗
    handleInsertMerchandise(row) {
      this.merchandise.index = row.index;
      this.merchandise.merchandiseName = row.merchandiseName;
    },
    // 表格项被焦点
    handleFocusTd(val) {
      this.currentGoodsIndex = val.index;
      // 商品明细已经填写了 "名称 & 金额 & 税率"
      if (val.merchandiseName && val.amount && val.taxRate != null) {
        this.hadTdFocus = val.focus;
        this.discountStatus = val.itemProperty > 0 ? true : false;
      } else {
        this.hadTdFocus = false;
      }
    },
    handleDialog(e) {
      if (e === 'sure') {
        this.goodsLists.forEach(item => {
          item.no = this.orderNo
        })
        let info = {
          goods: this.goodsLists,
          orderNo: this.orderNo
        }
        this.$emit('getMergeGoodsList', JSON.parse(JSON.stringify(info)));
        this.$refs.invoiceGoodsList.computedInvoicePrice();
      }
      this.$emit('closeOrderDetailDialog', false);
      this.goodsIndex = 0;
    }
  }
};
</script>

<style scoped lang="scss">
.order_manual_invoice_view {
  .order_invoiced_mask_box {
    // position: relative;
    .order_invoiced_mask {
      background: #ccc;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 60px;
      opacity: 0;
      cursor: not-allowed;
    }
    .el-icon-circle-close {
      position: absolute;
      font-size: 30px;
      z-index: 100;
      color: #333;
      right: 13px;
      top: 13px;
      cursor: pointer;
    }
  }

  .cus_dialog_tit {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      color: #303133;
      margin: 0;
    }
    .order_status {
      margin-right: 100px;

      span {
        color: #ff7a08;
        font-size: 14px;
      }
    }
  }

  .con {
    width: 100%;
    padding: 0 40px;
    color: #9d5224;
    font-size: 14px;
  }
}
.invocie_info_list {
  list-style: none;
  text-align: left;
  font-size: 14px;

  .invocie_info_item {
    margin-bottom: 15px;

    label {
      color: #666;
    }
    span {
      color: #333;
    }
  }
}
.deliver_form {
  padding: 0 100px 0 40px;
  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss">
.order_seach_form {
  margin-bottom: 16px;
  text-align: left;
  .el-input {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  .el-input__inner {
    border-bottom: none !important;
    // border: 1px solid #dcdfe6;
    border-radius: 4px !important;
  }
  .el-input__inner:focus {
    outline: none;
    border: 1px solid #409eff !important;
  }
  .data_from {
    color: #303133;
    margin-left: 20px;
    font-size: 14px;
  }
}
</style>
