<template>
  <div class="dialog-wrap">
    <el-row>
      <el-col :span="20">增值税电子普票：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.Dzpp }}</span>&nbsp;张</el-col>
    </el-row>
    <el-row>
      <el-col :span="20">增值税电子专票：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.DZZP }}</span>&nbsp;张</el-col>
    </el-row>
    <el-row>
      <el-col :span="20">增值税纸质专票：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.Zzzp }}</span>&nbsp;张</el-col>
    </el-row>
    <el-row>
      <el-col :span="20">增值税纸质普票：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.Zzpp }}</span>&nbsp;张</el-col>
    </el-row>
    <el-row>
      <el-col :span="20">收购发票电子票：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.SGDP }}</span>&nbsp;张</el-col>
    </el-row>
    <el-row>
      <el-col :span="20">收购发票纸质票：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.SGZP }}</span>&nbsp;张</el-col>
    </el-row>
    <el-row>
      <el-col :span="20">电子发票(普通发票)：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.QDPP }}</span>&nbsp;张</el-col>
    </el-row>
    <el-row>
      <el-col :span="20">电子发票（增值税专用发票）：</el-col>
      <el-col :span="4"><span style="font-size: 16px; font-weight: 500;">{{ size.QDZP }}</span>&nbsp;张</el-col>
    </el-row>
    <div style="padding-top:10px;font-size:14px;color:red;">
      若需要在电子税务局（线下）进行发票开具，点击“确认开具”后，需通过开票记录模块，“全电开票导出”功能进行导出。
    </div>

    <div class="dialog-footer">
      <el-button type="default" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleBill" :loading="loading">确认开具</el-button>
    </div>
  </div>
</template>

<script>
import {batchBillInvoice, invItemValid} from '@/service/sal/invoice'

export default {
  name: 'InvoiceBillBatch',
  props: ['invoices'],
  watch: {
    invoices: {
      handler () {
        this.invoices.forEach(i => this.size[i.billingType] += 1)
      },
      immediate: true
    }
  },
  data () {
    return {
      size: {
        Dzpp: 0,
        Zzzp: 0,
        Zzpp: 0,
        DZZP: 0,
        SGDP: 0,
        SGZP: 0,
        QDZP: 0,
        QDPP: 0,
      },
      loading: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    async valid() {
      const {success} = await invItemValid(this.invoices.map((item) => item.id))
      if (success) {
        return true
      }
    },
    // 确认开具
    async handleBill() {
      this.loading = true
      let param = {
        'ids': this.invoices.map((item) => item.id),
        'printable': false
      }
      if (!await this.valid()) {
        return;
      }
      const {success, message} = await batchBillInvoice(param)
      this.loading = false
      if (success) {
        this.toast('请求批量开具发票完成...', 'success')
        setTimeout(() => {
          this.handleClose()
          this.$emit('success')
        }, 1000)
      } else {
        return this.toast(message, 'error')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .dialog-wrap {
    margin-top: -30px;
    font-size: 14px;
    color: #333333;
    text-align: left;
    padding: 0 24px 24px;

    .el-row {
      margin-top: 12px;

      .el-col:first-child {
        color: #666666;
      }
    }

    .dialog-footer {
      margin-top: 24px;
      text-align: right;
    }
  }
</style>
