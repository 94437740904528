<template>
  <!-- 复制弹窗 -->
  <el-dialog append-to-body
             title="系统提示"
             :visible.sync="copyVisible"
             width="500px">
    <div class="copy-cont">
      是否确认复制
      <span v-if="!!invoice.dataId">
        业务单号为&nbsp;<b class="copy-code">{{ invoice.dataId }}</b>&nbsp;的完整
      </span>
      发票信息，并跳转至手工开票？
    </div>
    <div slot="footer">
      <el-button type="default" @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleCopyTo">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { selectInvoice } from '@/service/sal/invoice'

export default {
  name: 'InvoiceCopy',
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      copyVisible: false,
      copyDisabled: false,
    }
  },
  methods: {
    open () {
      this.copyVisible = true
    },
    handleCancel () {
      this.copyVisible = false
    },
    async handleCopyTo () {
      if (!this.invoice.id) {
        this.toast('复制发票失败，无效的发票主键！', 'warning')
        return
      }

      this.copyDisabled = true
      const { success, data } = await selectInvoice(this.invoice.id)
      this.copyVisible = false
      this.copyDisabled = false
      if (success) {
        this.$store.commit('invoiceIssue/setCopyInvoice', data)
        this.$emit('success', data)
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .copy-cont {
    padding: 0px 20px 20px 20px;

    .copy-code {
      color: #F5222D;
      font-weight: bold;
    }
  }
</style>
