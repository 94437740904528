<template>
  <div>
    <span>{{label}}</span>
    <div class="order">
      <i class="el-icon-caret-top" :class="active==='asc' ? 'active' : ''" @click="handleOrder('asc')"></i>
      <i class="el-icon-caret-bottom" :class="active==='desc' ? 'active' : ''" @click="handleOrder('desc')"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'POrderColumn',
  props: ['label', 'prop'],
  data () {
    return {
      active: ''
    }
  },
  methods: {
    reset () {
      this.active = ''
      // console.log("had reset")
    },
    handleOrder (order) {
      this.active = this.active === order ? '' : order
      this.$emit('sort', { prop: this.prop, order: this.active })
    }
  }
}
</script>

<style scoped lang="scss">
  .order {
    display: inline-block;
    position: relative;
    font-size: 15px;
    left: 4px;
    top: -6px;

    i {
      color: #C0C4CC;
      position: absolute;
    }

    .active {
      color: #3D94FF;
    }

    i:hover {
      cursor: pointer;
    }

    i:first-child {
      top: -12px;
    }
  }

</style>