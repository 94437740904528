import Cookies from 'js-cookie'

export default {

  getValue: (key) => {
    return Cookies.get(key)
  },
  setValue: (key, value) => {
    Cookies.set(key, value)
  }

}