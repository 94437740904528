<template>
  <div class="page-main edit_info_box">
    <el-form class="edit-select" ref="itemEditForm" :model="invoiceDetail" :rules="itemDetailRule">
      <div class="edit-select-item">
        <el-form-item prop="merchandiseCode" label="商品编码">
          <el-input disabled v-model.trim="invoiceDetail.merchandiseCode"/>
        </el-form-item>
        <el-form-item prop="merchandiseName" label="商品名称">
          <el-input disabled v-model="invoiceDetail.merchandiseName"></el-input>
        </el-form-item>
        <el-form-item prop="specification" label="规格型号">
          <el-input v-model="invoiceDetail.specification"></el-input>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="taxClassCode" label="税收分类编码" required>
          <el-input v-model="invoiceDetail.taxClassCode" placeholder="请输入税收分类编码">
            <i @click="handleChooseTaxClassCode" slot="suffix" class="el-icon-more" style="color: #409eff; cursor: pointer"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="taxClassName" label="税收分类名称" required>
          <el-input v-model="invoiceDetail.taxClassName" placeholder="请输入税收分类名称" />
        </el-form-item>
        <el-form-item prop="unit" label="单位">
          <el-input v-model="invoiceDetail.unit" />
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="quantity" label="数量">
          <el-input-number :controls="false" :precision="8" v-model="invoiceDetail.quantity" type="number" @blur="handleBlur('quantity', invoiceDetail)" placeholder="请输入数量" />
        </el-form-item>
        <el-form-item prop="unTaxPrice" label="单价">
          <el-input-number :controls="false" :precision="8" v-model="invoiceDetail.unTaxPrice" @blur="handleKeyupGoodsUnTaxPrice(invoiceDetail)" type="number" placeholder="请输入单价" />
        </el-form-item>
        <el-form-item prop="taxRate" label="税率" required>
          <el-select v-model="invoiceDetail.taxRateModel" @change="computedTaxAmount(invoiceDetail)" wdith=" 100px">
            <el-option v-for="(tax, idx) in taxRateData" :key="tax.value + idx" :value="tax.value" :label="tax.label"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="amount" label="金额" required>
          <el-input-number :controls="false" v-model="invoiceDetail.amount" :precision="2" @blur="handleBlur('amount', invoiceDetail)" type="number" placeholder="请输入金额" />
        </el-form-item>
        <el-form-item prop="taxAmount" label="税额" required>
          <el-input v-model="invoiceDetail.taxAmount" disabled placeholder="请输入税额" />
        </el-form-item>
        <el-form-item prop="sumAmount" label="价税合计" required>
          <el-input-number :controls="false"  v-model="invoiceDetail.sumAmount" :precision="2" @blur="handleBlur('sumAmount', invoiceDetail)" type="number" placeholder="请输入价税合计" />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleConfirm" >确定</el-button>
    </div>
    <!-- 税收分类编码弹框 -->
    <el-dialog append-to-body title="选择税收分类编码" :visible.sync="taxClassVisible" :show-close="false">
      <tax-class-code-mapping @handleCurrentGoods="handleCurrent"></tax-class-code-mapping>
    </el-dialog>

  </div>
</template>

<script>
import TaxClassCodeMapping from '@/views/system/merchandise/TaxClassCodeMapping';
import { changeMethods, dateOption, tableStyle } from '@/util/mixins';
import {invoiceDetailRedact} from "@/service/sal/invoice";
import {syncByMerchandiseCode} from "@/service/merchandise";

export default {
  name: 'InvoiceItemEdit',
  mixins: [tableStyle, dateOption, changeMethods],
  props: {
    invoiceId: {
      type: Number,
      default: 0
    },
    invoiceDetail: {
      type: Object,
      default: () => {

      }
    }
  },
  components: { TaxClassCodeMapping },
  data() {
    return {
      rowData: {},
      taxClassName: '税收分类编码',
      taxClassCode: '',
      merchandiseCode: '',
      taxRate: '',
      isTaxPreferential: '',
      taxPreferentialContent: '',
      taxRateModel: '',
      taxRateData: [
      {
        value: 0.0,
        label: '0%'
      },
      {
        value: 0.01,
        label: '1%'
      },
      {
        value: 0.03,
        label: '3%'
      },
      {
        value: 0.04,
        label: '4%'
      },
      {
        value: 0.05,
        label: '5%'
      },
      {
        value: 0.06,
        label: '6%'
      },
      {
        value: 0.09,
        label: '9%'
      },
      {
        value: 0.1,
        label: '10%'
      },
      {
        value: 0.11,
        label: '11%'
      },
      {
        value: 0.13,
        label: '13%'
      },
      {
        value: 0.16,
        label: '16%'
      },
      {
        value: 0.17,
        label: '17%'
      },
      {
        value: '免税',
        label: '免税'
      },
      {
        value: '不征税',
        label: '不征税'
      }
      ],
      taxClassVisible: false,
      computedInput: '', // 当前计算的输入框,
      itemDetailRule: {
        price: [
          {
            pattern: /^([-+])?\d+(\.[0-9]{1,8})?$/,
            message: '请输入正确的单价'
          }
        ],
        taxClassCode: [{ required: true, message: '请输入税收分类编码!' }],
        taxRateModel: [{ required: true, message: '请选择税率!' }],
        taxClassName: [{ required: true, message: '请输入税收分类名称!' }],
        amount: [{ required: true, message: '请输入金额!' }],
        taxAmount: [{ required: true, message: '请输入税额!' }],
        sumAmount: [{ required: true, message: '请输入价税合计!' }],
      },
    };
  },
  created() {
  },
  mounted() {
  },
  watch: {
    invoiceDetail: {
      immediate: true, // 立即执行
      deep: true, // 深度监听复杂类型内变化
      handler (newVal, oldVal) {
        if (newVal.taxPreferentialContent == '免税' || newVal.taxPreferentialContent == '不征税') {
          newVal.taxRateModel = newVal.taxPreferentialContent;
          newVal.taxAmount = "***"
        } else {
          newVal.taxRateModel = Number(newVal.taxRate);
        }
      }
    }
  },
  methods: {
    handleConfirm() {
      this.$refs.itemEditForm.validate((valid) => {
        if (valid) this.handleFormConfirm();
      });
    },
    async handleFormConfirm() {
      try {
        if(this.invoiceDetail.taxAmount == '***'){
          this.invoiceDetail.taxAmount = 0;
        }
        const { success } = await invoiceDetailRedact(this.invoiceDetail);
        this.handleCancel();
        success &&
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.$confirm('是否将此“商品编码”对应的商品信息模块的税分信息及税率同步更新?', '提示').then(() => {
          this.syncMerchandiseInfo();
        });
      } catch (error) {
        this.$message({
          message: '修改失败',
          type: 'warning'
        });
      }
    },
    async syncMerchandiseInfo() {
      if (this.invoiceDetail.merchandiseCode != null && this.invoiceDetail.merchandiseCode.length > 0) {
        const { success } = await syncByMerchandiseCode({
          taxClassCode: this.invoiceDetail.taxClassCode,
          taxClassName: this.invoiceDetail.taxClassName,
          taxRate: this.invoiceDetail.taxRate,
          merchandiseCode: this.invoiceDetail.merchandiseCode,
          isTaxPreferential: this.invoiceDetail.isTaxPreferential,
          taxPreferentialContent: this.invoiceDetail.taxPreferentialContent
        });
        if (success) {
          this.toast('更新成功！', 'success');
        }
      }
    },

    handleCancel() {
      this.$refs.itemEditForm.resetForm();
      this.$emit('handleItemEditDialog');
    },
    handleCurrent(row) {
       console.log(row)
      this.invoiceDetail.taxClassName = row.taxClassName;
      this.invoiceDetail.taxClassCode = row.taxClassCode;
      this.invoiceDetail.taxRate = row.taxRate.replace('%', '') / 100;
      this.taxClassVisible = false;
      if (row.taxPreferentialContent != '') {
        this.invoiceDetail.isTaxPreferential = '1';
        this.invoiceDetail.taxPreferentialContent = row.taxPreferentialContent;
      } else {
        this.invoiceDetail.isTaxPreferential = '0';
        this.invoiceDetail.taxPreferentialContent = '';
      }
      this.invoiceDetail.taxRateModel = row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税' ? row.taxPreferentialContent : this.invoiceDetail.taxRate;
      this.computedTaxAmount(this.invoiceDetail);
    },
    handleChooseTaxClassCode() {
      this.taxClassVisible = true;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then((_) => {
            done();
          })
          .catch((_) => {});
    },
    // 计算税额
    computedTaxAmount(invoiceDetail, currentInput='') {
      if (invoiceDetail.taxRateModel == '免税' || invoiceDetail.taxRateModel == '不征税') {
        invoiceDetail.isTaxPreferential = '1';
        invoiceDetail.taxPreferentialContent = invoiceDetail.taxRateModel;
        invoiceDetail.taxAmount = '***';
        invoiceDetail.amount = invoiceDetail.sumAmount;
        invoiceDetail.taxRate = 0;
        return;
      }else{
        invoiceDetail.isTaxPreferential = '0';
        invoiceDetail.taxPreferentialContent = '';
      }
      invoiceDetail.taxRate = invoiceDetail.taxRateModel;

      this.computedGoodsInfoByTax(invoiceDetail, 'amount');
      if(currentInput != 'unTaxPrice'){
        this.computedGoodsInfoByTax(invoiceDetail, 'unTaxPrice');
      }
      this.computedGoodsInfoByTax(invoiceDetail, 'price');

      if (Math.abs(invoiceDetail.sumAmount)) {
        // 含税金额/（1 + 税率）*税率
        invoiceDetail.taxAmount = Number(Number((Number(invoiceDetail.sumAmount) / (Number(invoiceDetail.taxRate) + 1)) * Number(invoiceDetail.taxRate)).toFixed(2));
      } else {
        invoiceDetail.taxAmount = 0;
      }
      invoiceDetail.taxAmount = invoiceDetail.taxAmount == 0 ? null : invoiceDetail.taxAmount;
    },

    // 输入框失去焦点
    handleBlur(type, val) {
      type != 'quantity' ? (this.computedInput = '') : null;
      switch (type) {
        case 'quantity':
          this.handleKeyupQuantity(val);
          break;
        case 'amount':
          this.handleKeyupGoodsAmount(val);
          break;
        case 'sumAmount':
          this.handleKeyupGoodsSumAmount(val);
          break;
      }
    },

    // 商品含税金额计算
    handleKeyupGoodsSumAmount(goods) {
      this.computedGoodsInfoByTax(goods, 'amount');
      // 焦点数量输入框时 - 计算数量
      if (this.computedInput == 'quantity') {
        if (goods.price == 0) return;
        goods.quantity = Number(Number(Number(goods.sumAmount) / Number(goods.price)).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedTaxAmount(goods);
        return;
      }
      // 数量不为零 - 计算单价
      if (Math.abs(goods.quantity)) {
        this.computedGoodsInfoByBlur(goods, 'price');
        this.computedTaxAmount(goods);
        return;
      }
      // 单价不为零 - 计算数量
      if (Math.abs(goods.price)) {
        this.computedInput = 'quantity';
        goods.quantity = Number(Number(Number(goods.sumAmount) / Number(goods.price)).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedGoodsInfoByTax(goods, 'unTaxPrice');
      }
      this.computedTaxAmount(goods);
    },

    handleKeyupGoodsAmount(goods) {
      this.computedGoodsInfoByTax(goods, 'sumAmount');
      // 焦点数量输入框时 - 计算数量
      if (this.computedInput == 'quantity') {
        goods.quantity = Number(Number(Number(goods.amount) / Number(goods.unTaxPrice)).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedTaxAmount(goods);
        return;
      }
      // 数量不为零 - 计算单价
      if (Math.abs(goods.quantity)) {
        this.computedGoodsInfoByBlur(goods, 'unTaxPrice');
        this.computedTaxAmount(goods);
        return;
      }
      if (Math.abs(goods.unTaxPrice)) {
        this.computedInput = 'quantity';
        goods.quantity = Number(Number(Number(goods.amount) / Number(goods.unTaxPrice)).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
      }
      this.computedTaxAmount(goods);
    },

    // 商品数量计算
    handleKeyupQuantity(goods) {
      // 单价不为零 - 计算价税合计
      if (goods.unTaxPrice && goods.unTaxPrice != 0) {
        this.computedGoodsInfoByBlur(goods, 'amount');
      }
      // 单价为零 && 价税合计不为零 - 计算单价
      if (Math.abs(goods.quantity) == 0) return;
      // if (Math.abs(goods.sumAmount) && !goods.price) {
      //   goods.price = Number(Number(Number(goods.sumAmount) / Number(goods.quantity)).toFixed(8));
      //   goods.price = Math.abs(goods.price);
      //   goods.price = goods.price == 0 ? null : goods.price;
      //   this.computedGoodsInfoByTax(goods, 'unTaxPrice');
      // }
      if (Math.abs(goods.amount) && !goods.unTaxPrice) {
        goods.unTaxPrice = Number(Number(Number(goods.amount) / Number(goods.quantity)).toFixed(8));
        goods.unTaxPrice = Math.abs(goods.unTaxPrice);
        goods.unTaxPrice = goods.unTaxPrice == 0 ? null : goods.unTaxPrice;
        this.computedGoodsInfoByTax(goods, 'price');
      }

      this.computedTaxAmount(goods, 'unTaxPrice');
    },

    handleKeyupGoodsUnTaxPrice(goods) {
      console.log("计算单价")
      // 焦点数量输入框时 - 计算数量
      if (this.computedInput == 'quantity') {
        goods.quantity = Number(Number(Number(goods.amount) / Number(goods.unTaxPrice)).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedTaxAmount(goods, 'unTaxPrice');
        return;
      }
      // 数量不为零 - 计算未税金额
      if (Math.abs(goods.quantity)) {
        this.computedGoodsInfoByBlur(goods, 'amount');
      }
      // 未税金额不为零 && 数量为零 - 计算数量
      if (Math.abs(goods.amount) && !goods.quantity) {
        this.computedInput = 'quantity';
        goods.quantity = Number(Number(Number(goods.amount) / Number(goods.unTaxPrice)).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
      }
      this.computedTaxAmount(goods, 'unTaxPrice');
    },

    // 失去焦点时 - 计算票面信息
    computedGoodsInfoByBlur(goods, type = '') {
      switch (type) {
        case 'unTaxPrice':
          goods.unTaxPrice = Number(Number(Number(goods.amount) / Number(goods.quantity)).toFixed(8));
          goods.unTaxPrice = Math.abs(goods.unTaxPrice);
          goods.unTaxPrice = goods.unTaxPrice == 0 ? null : goods.unTaxPrice;
          this.computedGoodsInfoByTax(goods, 'price');
          this.computedGoodsInfoByTax(goods, 'sumAmount');
          break;
        case 'price':
          goods.price = Number(Number(Number(goods.sumAmount) / Number(goods.quantity)).toFixed(8));
          goods.price = Math.abs(goods.price);
          goods.price = goods.price == 0 ? null : goods.price;
          this.computedGoodsInfoByTax(goods, 'unTaxPrice');
          this.computedGoodsInfoByTax(goods, 'amount');
          break;
        case 'amount':
          goods.amount = Number(Number(Number(goods.quantity) * Number(goods.unTaxPrice)).toFixed(2));
          goods.amount = goods.amount == 0 ? null : goods.amount;
          this.computedGoodsInfoByTax(goods, 'sumAmount');
          this.computedGoodsInfoByTax(goods, 'price');
          break;
        case 'sumAmount':
          goods.sumAmount = Number(Number(Number(goods.quantity) * Number(goods.price)).toFixed(2));
          goods.sumAmount = goods.sumAmount == 0 ? null : goods.sumAmount;
          this.computedGoodsInfoByTax(goods, 'amount');
          this.computedGoodsInfoByTax(goods, 'unTaxPrice');
          break;
      }
    },

    // 根据税率 计算票面信息
    computedGoodsInfoByTax(goods, type = '') {
      switch (type) {
        case 'unTaxPrice':
          // 未税单价 = 未税金额 / 商品数量
          goods.unTaxPrice = goods.quantity ? Number(Number(Number(goods.amount) / Number(goods.quantity)).toFixed(8)) : 0;
          goods.unTaxPrice = goods.unTaxPrice == 0 ? null : goods.unTaxPrice;
          break;
        case 'price':
          // 单价 = 价税合计 / 商品数量
          goods.price = goods.quantity ? Number(Number(Number(goods.sumAmount) / Number(goods.quantity)).toFixed(8)) : 0;
          goods.price = goods.price == 0 ? null : goods.price;
          break;
        case 'amount':
          // 金额 = 价税合计/（1 + 税率）
          goods.amount = Number(Number(Number(goods.sumAmount) / (1 + Number(goods.taxRate))).toFixed(2));
          goods.amount = goods.amount == 0 ? null : goods.amount;
          break;
        case 'sumAmount':
          // 价税合计 = 金额 *（1 + 税率）
          goods.sumAmount = Number(Number(Number(goods.amount) * (1 + Number(goods.taxRate))).toFixed(2));
          goods.sumAmount = goods.sumAmount == 0 ? null : goods.sumAmount;
          break;
      }
    },

    /* 表格列值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '税率':
          if (['1', 'Y', 1].indexOf(row.isTaxPreferential) != -1) {
            return row.taxPreferentialContent;
          }
          return Number(value * 100) + '%';
        case '税额':
          if (value == 0) return '***';
          return '￥' + value;
        case '金额':
        case '价税合计':
          return '￥' + value;
        case '数量':
          if (value == 0) return '';
          return value;
        case '单价':
          if (value == 0) return '';
          return value;
        case '商品行性质':
          if (value == 0) {
            return '正常';
          } else if (value == 1) {
            return '折扣行';
          } else if (value == 2) {
            return '被折扣行';
          } else {
            return '--';
          }
        default:
          return value;
      }
    },

    isNullOrZero(num) {
      return num == null || num == 0;
    },
  }
};
</script>

<style scoped lang="scss">
.el-dialog__body {
  margin-top: 40px;
}
</style>
<style lang="scss" scoped>

.edit-select {
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 24px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.page-main {
  padding: 0 24px;

  .tax-class-code {
    height: 32px;
    width: 170px;
    display: flex;
    padding: 0 15px;
    border-radius: 4px;
    align-content: center;
    border: 1px solid #dcdfe6;
    justify-content: space-between;

    i {
      color: #3d94ff;
      line-height: 32px;
      margin-right: -4px;
    }
  }
}

.drawer-header {
  margin: 22px 24px 6px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }

  .el-button {
    padding: 0;
  }

  .el-button:hover {
    text-decoration: underline;
  }
}

.drawer-header span:before {
  display: inline-block;
  content: '';
  width: 2px;
  height: 16px;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #333;
}

.content-main {
  margin-top: 0;
  padding: 0px 24px;

  .content-table {
    height: calc(100% - 40px);
  }
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  margin: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  //line-height: 20px;

  span {
    color: #333333;
  }
}

.dialog-content {
  margin: 24px 24px;
  text-align: left;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  //line-height: 26px;
}

.dialog-body {
  margin: 0 24px 24px;

  .el-button {
    margin-top: 5px;
    padding: 0;
    width: 20px;
    height: 20px;
    //line-height: 0px;
    font-size: 20px;
  }

  .el-input {
    width: auto;
    text-align: center;
  }
}

.dialog-footer {
  text-align: center;
  margin: 10px auto;
  padding-bottom: 20px;
}

//::v-deep .el-table .cell {
//  height: 34px;
//  line-height: 34px;
//}



</style>
<style scoped lang="scss">
.edit_info_box{
  .el-form-item__error{
    top:66px!important;
  }
  .el-input-number{
   width: 200px;
  }
}

</style>
